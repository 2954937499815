export const importFromCsv = {
  select: '選択',
  icChipNo: 'ICチップNo.',
  productName: '商品名',
  size: 'サイズ',
  productCode: '商品CD',
  barcode: 'バーコードNo.',
  importSuccessfully: 'ユニフォームのインポートが完了しました。',
  importFailed: 'ユニフォームのインポートが失敗しました。',
}
