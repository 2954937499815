import { useCallback, useState } from 'react'

import { deleteAnnouncement, getAnnouncements } from '@/apis/announcements'
import { API_LIMIT } from '@/constants/api'
import useQueryPage from '@/hooks/useQueryPage'
import { DeleteAnnouncementRequest, GetAnnouncementsResponse } from '@/types/apis/announcements'

export const useAnnouncements = () => {
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [getAnnouncementsLoading, setGetAnnouncementsLoading] = useState(false)
  const [deleteAnnouncementLoading, setDeleteAnnouncementLoading] = useState(false)
  const [getAnnouncementsResponse, setAnnouncementsResponse] = useState<GetAnnouncementsResponse>()
  const get = useCallback(async () => {
    try {
      setGetAnnouncementsLoading(true)
      const res = await getAnnouncements({ page, limit: API_LIMIT })
      setAnnouncementsResponse(res)
    } finally {
      setGetAnnouncementsLoading(false)
    }
  }, [page])

  const del = useCallback(
    async ({ id }: DeleteAnnouncementRequest) => {
      try {
        setDeleteAnnouncementLoading(true)
        await deleteAnnouncement({ id })

        if (getAnnouncementsResponse?.items) {
          const newAnnouncements = getAnnouncementsResponse.items.filter((item) => item.id !== id)
          setAnnouncementsResponse({ ...getAnnouncementsResponse, items: newAnnouncements })
        }
      } finally {
        setDeleteAnnouncementLoading(false)
      }
    },
    [getAnnouncementsResponse]
  )

  return {
    getAnnouncementsLoading,
    getAnnouncements: get,
    page,
    pageSize: API_LIMIT,
    setPage,
    getAnnouncementsResponse,
    deleteAnnouncement: del,
    deleteAnnouncementLoading,
  }
}
