export const create = {
  form: {
    newAnnouncement: 'New message',
    text: 'Japanese translation',
    startAt: 'Start At',
    endAt: 'End At',
    requireText: 'Please input Japanese translation!',
    requireStartAt: 'Please input Start At field!',
    requireEndAt: 'Please input End At field!',
    requireEmployeeIds: 'Please select target employees',
    summit: 'Submit',
    translations: 'Translations',
    specialType: 'Special type',
    select: 'Select',
    happyBirthday: 'Birthday message',
    joiningCompany: 'Joining company message',
    targetFilter: 'Target filter',
    male: 'Male',
    female: 'Female',
  },
  pageHeader: {
    title: 'Create message',
    back: 'Back',
  },
}
