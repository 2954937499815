import { layout } from '@/components/Layout/Layout.i18n.ja'
import { pages as componentPages } from '@/components/pages/pages.i18n.ja'
import { pages } from '@/pages/pages.i18n.ja'

import common from './common'

const translation = {
  translation: {
    components: {
      layout,
      pages: componentPages,
    },
    pages,
    locales: {
      english: '英語',
      japanese: '日本',
    },
    common,
  },
}

export default translation
