import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getLanguage, updateLanguage } from '@/apis/languages'
import useNotification from '@/hooks/useNotification'
import {
  GetLanguageRequest,
  GetLanguageResponse,
  UpdateLanguageRequest,
  UpdateLanguageResponse,
} from '@/types/apis/languages'

export const useUpdateLanguage = () => {
  const { t } = useTranslation()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<UpdateLanguageResponse>()

  const update = useCallback(
    async (request: UpdateLanguageRequest) => {
      try {
        setLoading(true)
        const res = await updateLanguage(request)
        setResponse(res)
        openNotification({ description: t('pages.languages.update.updateSuccessfully') })
      } finally {
        setLoading(false)
      }
    },
    [openNotification, t]
  )

  return {
    loading,
    updateLanguage: update,
    response,
  }
}

export const useGetLanguage = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<GetLanguageResponse>()

  const get = useCallback(async (request: GetLanguageRequest) => {
    try {
      setLoading(true)
      const res = await getLanguage(request)
      setResponse(res)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    getLanguage: get,
    response,
  }
}
