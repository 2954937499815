export const create = {
  form: {
    code: 'コード',
    name: '品名',
    size: 'サイズ',
    submit: '作成する',
    requireCode: 'コードは必須です',
    requireName: '品名は必須です',
    requireSize: 'サイズは必須です',
  },
  pageHeader: {
    title: 'ユニフォーム情報作成',
    back: '戻る',
  },
}
