import moment from 'moment'
import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'

import { deleteAttendanceLog, exportAttendanceLogs, getAttendanceLogs } from '@/apis/attendanceLogs'
import { IAttendanceLogFilter } from '@/components/pages/AttendanceLogs/List/Filter'
import { apiHost } from '@/configs/api'
import { API_LIMIT } from '@/constants/api'
import useQuery from '@/hooks/useQuery'
import useQueryPage from '@/hooks/useQueryPage'
import { LogType } from '@/types'
import { GetAttendanceLogsResponse } from '@/types/apis/attendanceLogs'
import { DeleteEmployeeRequest } from '@/types/apis/employees'

export type PageQueryParams = {
  fromDate?: string
  toDate?: string
  logType?: LogType
  kanaCharacters?: string
  page?: number
}

export const useAttendanceLogs = () => {
  const currentPage = useQueryPage()
  const query = useQuery()
  const [page, setPage] = useState(currentPage)
  const [filter, setFilter] = useState<IAttendanceLogFilter>({})
  const [getAttendanceLogsLoading, setGetAttendanceLogsLoading] = useState(false)
  const [deleteAttendanceLogLoading, setDeleteAttendanceLogLoading] = useState(false)
  const [getAttendanceLogsResponse, setAttendanceLogsResponse] = useState<GetAttendanceLogsResponse>()

  const filterToQuery = (filter: IAttendanceLogFilter & { page?: number }): string => {
    const filterQuery: PageQueryParams = {}

    if (filter.dateRange) {
      filterQuery.fromDate = filter.dateRange?.[0]?.startOf('day').format()
      filterQuery.toDate = filter.dateRange?.[1]?.endOf('day').format()
    }

    if (filter.logType) {
      filterQuery.logType = filter.logType
    }

    if (filter.kanaCharacters) {
      filterQuery.kanaCharacters = filter.kanaCharacters.join(',')
    }

    if (filter.page) {
      filterQuery.page = filter.page
    }

    return qs.stringify(filterQuery)
  }

  const get = useCallback(async () => {
    const [fromDate, toDate] = filter.dateRange || []
    try {
      setGetAttendanceLogsLoading(true)
      const res = await getAttendanceLogs({
        page,
        limit: API_LIMIT,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        logType: filter.logType,
        kanaCharacters: filter.kanaCharacters?.join(','),
      })
      setAttendanceLogsResponse(res)
    } finally {
      setGetAttendanceLogsLoading(false)
    }
  }, [page, filter])

  const del = useCallback(
    async (request: DeleteEmployeeRequest) => {
      try {
        setDeleteAttendanceLogLoading(true)
        await deleteAttendanceLog(request)

        if (getAttendanceLogsResponse?.items) {
          const newEmployees = getAttendanceLogsResponse.items.filter((item) => item.id !== request.id)
          setAttendanceLogsResponse({ ...getAttendanceLogsResponse, items: newEmployees })
        }
      } finally {
        setDeleteAttendanceLogLoading(false)
      }
    },
    [getAttendanceLogsResponse]
  )

  const exportExcel = async (filterParamsString: string) => {
    const filter = qs.parse(filterParamsString) as PageQueryParams
    // TODO: call API and open url in a new tab
    const result = await exportAttendanceLogs({
      filter,
    })
    window.open(`${apiHost}${result.url}`, '_blank')
  }

  useEffect(() => {
    const fromDate = query.get('fromDate') ? moment(query.get('fromDate')) : null
    const toDate = query.get('toDate') ? moment(query.get('toDate')) : null
    const logType = (query.get('logType') as LogType) || undefined
    const page = query.get('page')
    const kanaCharacters = query.get('kanaCharacters')

    const filterParams: IAttendanceLogFilter = {
      dateRange: [fromDate, toDate],
      logType,
      kanaCharacters: kanaCharacters?.split(','),
    }

    setFilter(filterParams)
    setPage(page ? Number(page) : 1)
  }, [query])

  return {
    getAttendanceLogsLoading,
    deleteAttendanceLogLoading,
    getAttendanceLogs: get,
    deleteAttendanceLog: del,
    page,
    pageSize: API_LIMIT,
    setPage,
    getAttendanceLogsResponse,
    filter,
    setFilter,
    exportExcel,
    filterToQuery,
  }
}
