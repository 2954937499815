export const update = {
  form: {
    text: '文書',
    startAt: '発効日',
    endAt: '終了日',
    public: '全体のメッセージ',
    question: '質問タイプ',
    translations: '翻訳',
    employees: '対象社員',
    update: '保存する',
    requireText: '文書が必須です。',
    requireStartAt: '発効日が必須です。',
    requireEndAt: '終了日が必須です。',
    requireEmployeeIds: '対象を選択してください',
    updateSuccessfully: '保存しました。',
    requireSelect: '選択',
    specialType: '特別なタイプ',
    select: '選択',
    happyBirthday: 'お誕生日のおめでとう',
    joiningCompany: '入社日のおめでとう',
    targetFilter: '配信グループ(任意)',
    male: '男',
    female: '女',
  },
  pageHeader: {
    title: 'お知らせ',
    back: '戻る',
  },
}
