import { PageHeader } from 'antd'
import { useTranslation } from 'react-i18next'

const UniformsPageHeader = () => {
  const { t } = useTranslation()

  return <PageHeader ghost={false} title={t('components.pages.uniforms.list.pageHeader.title')} />
}

UniformsPageHeader.displayName = 'UniformsPageHeader'
export default UniformsPageHeader
