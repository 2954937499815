import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { importUniformsFromCsv } from '@/apis/uniforms'
import routes from '@/constants/routes'
import useNotification from '@/hooks/useNotification'
import { ImportUniformsFromCsvRequest, ImportUniformsFromCsvResponse } from '@/types/apis/uniforms'

export const useImportUniforms = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<ImportUniformsFromCsvResponse>()

  const imp = useCallback(
    async (request: ImportUniformsFromCsvRequest) => {
      try {
        setLoading(true)
        const res = await importUniformsFromCsv(request)
        setResponse(res)
        openNotification({ description: t('pages.uniforms.importFromCsv.importSuccessfully') })
        navigate(routes.uniforms.path)
      } catch (error) {
        openNotification({ description: t('pages.uniforms.importFromCsv.importFailed'), type: 'error' })
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )

  return {
    loading,
    importUniforms: imp,
    response,
  }
}
