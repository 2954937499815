import React from 'react'

import { CreateTeminalForm, CreateTerminalPageHeader } from '@/components/pages/Terminals/Create'
import { useCreateTerminals } from '@/pages/Terminals/Create/Create.hooks'

const CreateTerminal = () => {
  const { createTerminal, loading } = useCreateTerminals()

  return (
    <>
      <CreateTerminalPageHeader />
      <CreateTeminalForm {...{ loading }} onSubmit={createTerminal} />
    </>
  )
}

CreateTerminal.displayName = 'CreateTerminal'
export default CreateTerminal
