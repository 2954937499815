import { Button, Popconfirm, Space, Table } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { Terminal } from '@/types'
import { GetTerminalsResponse } from '@/types/apis/terminals'

type Props = {
  dataSource: GetTerminalsResponse | undefined
  getTerminalsLoading: boolean
  onDelete: (id: Terminal['id']) => void
}

const TerminalsTable = ({ dataSource, getTerminalsLoading, onDelete }: Props) => {
  const { t } = useTranslation()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})
  const navigate = useNavigate()
  const columns = [
    {
      title: t('components.pages.terminals.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.terminals.list.table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('components.pages.terminals.list.table.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('components.pages.terminals.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      // eslint-disable-next-line
      render: (text: string, record: Terminal) => {
        return (
          <Space size="middle">
            <Button type="link" size="small" onClick={() => goToEditTerminalPage(record.id)}>
              {t('components.pages.terminals.list.table.update')}
            </Button>
            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.terminals.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleConfirmDelete({})}
            >
              <Button type="link" size="small" danger onClick={() => setVisibleConfirmDelete({ [record.id]: true })}>
                {t('components.pages.terminals.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  const goToEditTerminalPage = (id: Terminal['id']) => {
    navigate(`${routes.terminals.path}/${id}`)
  }

  return (
    <Table rowKey="id" dataSource={dataSource} columns={columns} loading={getTerminalsLoading} pagination={false} />
  )
}
TerminalsTable.displayName = 'TerminalsTable'
export default TerminalsTable
