export const importFromCsv = {
  importModalTitle: 'Confirm import announcements',
  importSuccessfully: 'Import announcements successfully!',
  importFailed: 'Import announcemts failed!',
  select: 'Select',
  text: 'Text',
  startAt: 'Start At',
  endAt: 'End At',
  displayTarget: 'Display Target',
  ja: 'Japanese',
  en: 'English',
  cn: 'Chinese',
  th: 'Thai',
  vi: 'Vietnamese',
}
