import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CreateIdCardPageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.idCards.create.pageHeader.title')} />
}

CreateIdCardPageHeader.displayName = 'CreateIdCardPageHeader'
export default CreateIdCardPageHeader
