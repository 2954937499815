import {
  CreateAnnouncementRequest,
  CreateAnnouncementResponse,
  DeleteAnnouncementRequest,
  DeleteAnnouncementResponse,
  GetAnnouncementRequest,
  GetAnnouncementResponse,
  GetAnnouncementsRequest,
  GetAnnouncementsResponse,
  ImportAnnouncementsFromCsvRequest,
  ImportAnnouncementsFromCsvResponse,
  UpdateAnnouncementRequest,
  UpdateAnnouncementResponse,
} from '@/types/apis/announcements'

import axios from './axios'
const API_ENDPOINT = '/announcements'

export const getAnnouncements = (request: GetAnnouncementsRequest): Promise<GetAnnouncementsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const getAnnouncement = (request: GetAnnouncementRequest): Promise<GetAnnouncementResponse> => {
  return axios.get(`${API_ENDPOINT}/${request.id}`)
}

export const deleteAnnouncement = (request: DeleteAnnouncementRequest): Promise<DeleteAnnouncementResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const createAnnoucement = (request: CreateAnnouncementRequest): Promise<CreateAnnouncementResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const updateAnnouncement = (request: UpdateAnnouncementRequest): Promise<UpdateAnnouncementResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}`, request)
}

export const importAnnouncementFromCsv = (
  request: ImportAnnouncementsFromCsvRequest
): Promise<ImportAnnouncementsFromCsvResponse> => {
  return axios.post(`${API_ENDPOINT}/import-csv`, request)
}
