import {
  CreateEmployeeRequest,
  CreateEmployeeResponse,
  DeleteEmployeeRequest,
  DeleteEmployeeResponse,
  GetEmployeeRequest,
  GetEmployeeResponse,
  GetEmployeesRequest,
  GetEmployeesResponse,
  ImportEmployeeFromCsvRequest,
  ImportEmployeeFromCsvResponse,
  UpdateEmployeeRequest,
  UpdateEmployeeResponse,
} from '@/types/apis/employees'

import axios from './axios'

const API_ENDPOINT = '/employees'

export const getEmployees = (request: GetEmployeesRequest): Promise<GetEmployeesResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const getEmployee = (request: GetEmployeeRequest): Promise<GetEmployeeResponse> => {
  return axios.get(`${API_ENDPOINT}/${request.id}`)
}

export const createEmployee = (request: CreateEmployeeRequest): Promise<CreateEmployeeResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const updateEmployee = (request: UpdateEmployeeRequest): Promise<UpdateEmployeeResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}`, request)
}

export const deleteEmployee = (request: DeleteEmployeeRequest): Promise<DeleteEmployeeResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const importEmployeeFromCsv = (
  request: ImportEmployeeFromCsvRequest
): Promise<ImportEmployeeFromCsvResponse> => {
  return axios.post(`${API_ENDPOINT}/import-csv`, request)
}
