import { InboxOutlined } from '@ant-design/icons'
import { Spin, Upload } from 'antd'
import { UploadRequestOption } from 'rc-upload/es/interface'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Dragger } = Upload

type Props = {
  loading: boolean
  onUpload: (file: FormData) => void
}

const UploadCsv = ({ onUpload, loading }: Props) => {
  const { t } = useTranslation()
  const handleRequest = (options: UploadRequestOption) => {
    const formData = new FormData()
    formData.append('file', options.file)
    onUpload(formData)
  }

  return (
    <Spin spinning={loading}>
      <Dragger height={300} showUploadList={false} customRequest={handleRequest}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('components.pages.announcements.imports.form.clickOrDrag')}</p>
      </Dragger>
    </Spin>
  )
}

UploadCsv.displayName = 'UploadCsv'
export default UploadCsv
