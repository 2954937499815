export const create = {
  form: {
    code: 'Code',
    firstName: 'First name',
    lastName: 'Last name',
    firstNameKana: 'First name (Kana)',
    lastNameKana: 'Last name (Kana)',
    gender: 'Gender',
    genderMale: 'Male',
    genderFemale: 'Female',
    uid: 'IC Card No.',
    languageCode: 'Language Code',
    companyJoinDate: 'Company Join Date',
    dob: 'Dob',
    submit: 'Submit',
    requireFirstName: 'Please input employee first name!',
    requireLastName: 'Please input employee last name!',
    requireFirstNameKana: 'Please input employee first name (kana)!',
    requireLastNameKana: 'Please input employee last name (kana)!',
    requireCode: 'Please input employee code!',
    requireLanguageCode: 'Please input employee language code!',
    requireUid: 'Please input employee UID!',
    requireKana: 'Please input name Kana!',
    requireGender: 'Please select gender!',
    requireCompanyJoinDate: 'Please select date!',
    requireDob: 'Please select date!',
    select: 'Select',
  },
  pageHeader: {
    title: 'New employee',
    back: 'Back',
  },
}
