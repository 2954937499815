import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Language from '@/constants/language'
import { LANGUAGE_KEY } from '@/constants/storage'
import { en, ja } from '@/locales'
import * as localStorage from '@/utils/localStorage'

const language = localStorage.get(LANGUAGE_KEY) || Language.English
const resources = {
  en,
  ja,
}

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: Language.English,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
