export const list = {
  pageHeader: {
    title: 'LOGIN',
  },
  form: {
    requireName: 'Please input user name!',
    requirePassword: 'Please input your Password!',
    placeholderInput: 'User name',
    placeholderPassword: 'Password',
    btnLogin: 'Login',
  },
}
