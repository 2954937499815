import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { EmployeesPageHeader, EmployeesTable } from '@/components/pages/Employees/List'
import routes from '@/constants/routes'
import { Employee } from '@/types'

import { useEmployees } from './Employees.hooks'

const Employees = () => {
  const navigate = useNavigate()
  const {
    getEmployees,
    getEmployeesLoading,
    getEmployeesResponse,
    deleteEmployeeLoading,
    deleteEmployee,
    page,
    pageSize,
    filter,
    filterToQuery,
  } = useEmployees()
  const dataSource = getEmployeesResponse?.items ?? []
  const totalItems = getEmployeesResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    navigate({
      pathname: routes.employees.path,
      search: filterToQuery({
        ...filter,
        page: config.current ?? page,
      }),
    })
  }

  const handleChangeKanaSearch = (chars: string[]) => {
    navigate({
      pathname: routes.employees.path,
      search: filterToQuery({
        ...filter,
        kanaCharacters: chars,
        page,
      }),
    })
  }

  const handleDeleteEmployee = async (id: Employee['id']) => {
    await deleteEmployee({ id })
  }

  useEffect(() => {
    getEmployees().then()
  }, [getEmployees, filter])

  return (
    <>
      <EmployeesPageHeader onKanaSearchChange={handleChangeKanaSearch} />
      <EmployeesTable
        {...{ dataSource, getEmployeesLoading, page, pageSize, totalItems, deleteEmployeeLoading }}
        onChange={handleChangePage}
        onDelete={handleDeleteEmployee}
      />
    </>
  )
}

Employees.displayName = 'Employees'
export default Employees
