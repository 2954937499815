import { Space } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { LoginForm, LoginPageHeader } from '@/components/pages/Login/List'
import { LoginFormValue } from '@/components/pages/Login/List/Form'
import { useAuth } from '@/hooks/useAuth'

const Login: React.FC = () => {
  const { loading, login } = useAuth()
  const navigate = useNavigate()

  const handleLogin = async (formValues: LoginFormValue) => {
    await login(formValues)
    navigate('/')
  }

  return (
    <Space style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <LoginPageHeader />
      <LoginForm {...{ loading }} onSubmit={handleLogin} />
    </Space>
  )
}
Login.displayName = 'Login'
export default Login
