import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { createTerminal } from '@/apis/terminals'
import routes from '@/constants/routes'
import useNotification from '@/hooks/useNotification'
import { CreateTerminalRequest, CreateTerminalResponse } from '@/types/apis/terminals'

export const useCreateTerminals = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<CreateTerminalResponse>()

  const create = useCallback(
    async ({ name, description }: CreateTerminalRequest) => {
      try {
        setLoading(true)
        const res = await createTerminal({ name, description })
        setResponse(res)
        openNotification({ description: t('pages.terminals.create.createSuccessfully') })
        navigate(routes.terminals.path)
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )

  return {
    loading,
    createTerminal: create,
    response,
  }
}
