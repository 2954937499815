export const list = {
  table: {
    code: 'Code',
    name: 'Name',
    kana: 'Kana',
    gender: 'Gender',
    uid: 'IC Card No.',
    language: 'Language',
    companyJoinDate: 'Company join date',
    dob: 'DOB',
    action: 'Action',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this employee?',
  },
  pageHeader: {
    title: 'Employees',
    new: 'New',
    csvImport: 'CSV Import',
  },
  languages: {
    en: 'English',
    vi: 'Vietnamese',
    th: 'Thai',
    ja: 'Japanese',
    cn: 'Chinese',
  },
}
