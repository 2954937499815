import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UpdateTerminalPageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.terminals.update.pageHeader.title')} />
}

UpdateTerminalPageHeader.displayName = 'UpdateTerminalPageHeader'
export default UpdateTerminalPageHeader
