import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUniform, updateUniform } from '@/apis/uniforms'
import useNotification from '@/hooks/useNotification'
import {
  GetUniformRequest,
  GetUniformResponse,
  UpdateUniformRequest,
  UpdateUniformResponse,
} from '@/types/apis/uniforms'

export const useUpdateUniforms = () => {
  const { t } = useTranslation()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<UpdateUniformResponse>()

  const update = useCallback(
    async (request: UpdateUniformRequest) => {
      try {
        setLoading(true)
        const res = await updateUniform(request)
        setResponse(res)
        openNotification({ description: t('pages.uniforms.update.updateSuccessfully') })
      } finally {
        setLoading(false)
      }
    },
    [openNotification, t]
  )

  return {
    loading,
    updateUniform: update,
    response,
  }
}

export const useGetUniform = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<GetUniformResponse>()

  const get = useCallback(async (request: GetUniformRequest) => {
    try {
      setLoading(true)
      const res = await getUniform(request)
      setResponse(res)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    getUniform: get,
    response,
  }
}
