import { Modal } from 'antd'
import { VFC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  isVisible: boolean
  handleOk: () => void
}

export const UserTypeModal: VFC<Props> = ({ isVisible, handleOk }) => {
  const [t] = useTranslation()
  return (
    <Modal
      title={t('components.pages.admins.userTypeModal.title')}
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p style={{ whiteSpace: 'pre-wrap' }}>{t('components.pages.admins.userTypeModal.description')}</p>
    </Modal>
  )
}
UserTypeModal.displayName = 'UserTypeModal'
