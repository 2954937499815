import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateTerminalRequest } from '@/types/apis/terminals/CreateTerminalRequest'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateTerminalRequest) => void
}
const CreateTerminalForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.terminals.create.form.name')}
        name="name"
        rules={[{ required: true, message: t('components.pages.terminals.create.form.requireName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.terminals.create.form.description')}
        name="description"
        rules={[{ required: true, message: t('components.pages.terminals.create.form.requireDescription') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.terminals.create.form.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateTerminalForm.displayName = 'CreateTerminalForm'
export default CreateTerminalForm
