import { TablePaginationConfig } from 'antd'
import React, { useEffect } from 'react'

import { LanguagesPageHeader, LanguagesTable } from '@/components/pages/Languages/List'
import { Language } from '@/types'

import { useLanguages } from './Languages.hooks'

const Languages = () => {
  const { getLanguages, deleteLanguage, getLanguagesLoading, getLanguagesResponse, page, setPage } = useLanguages()

  const dataSource = getLanguagesResponse ?? []

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
  }

  const handleDeleteLanguage = async (id: Language['id']) => {
    await deleteLanguage({ id })
  }

  useEffect(() => {
    getLanguages().then()
  }, [getLanguages])

  return (
    <>
      <LanguagesPageHeader />
      <LanguagesTable
        {...{ dataSource, getLanguagesLoading }}
        onChange={handleChangePage}
        onDelete={handleDeleteLanguage}
      />
    </>
  )
}

Languages.displayName = 'Languages'
export default Languages
