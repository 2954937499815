export const create = {
  form: {
    newAnnouncement: 'メッセージ登録',
    text: '和訳',
    startAt: '開始日',
    endAt: '終了日',
    requireText: '和訳は必須です',
    requireStartAt: '開始日は必須です',
    requireEndAt: '終了日は必須です',
    requireEmployeeIds: '対象を選択してください',
    summit: '登録する',
    translations: '翻訳',
    specialType: '特別なタイプ',
    select: '選択',
    happyBirthday: 'お誕生日のおめでとう',
    joiningCompany: '入社日のおめでとう',
    targetFilter: '配信グループ(任意)',
    male: '男',
    female: '女',
  },
  pageHeader: {
    title: 'メッセージ登録',
    back: '戻る',
  },
}
