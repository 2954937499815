import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateUniformForm, UpdateUniformPageHeader } from '@/components/pages/Uniforms/Update'
import { UpdateUniformFormValue } from '@/components/pages/Uniforms/Update/Form'
import { useGetUniform, useUpdateUniforms } from '@/pages/Uniforms/Update/Update.hooks'

const UpdateUniform = () => {
  const params = useParams<{ id: string }>()
  const { updateUniform, loading: updateLoading } = useUpdateUniforms()
  const { getUniform, loading: getLoading, response: getUniformResponse } = useGetUniform()

  const uniformId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateUniformFormValue) => {
    await updateUniform({ ...formValues, id: uniformId })
  }

  useEffect(() => {
    getUniform({ id: uniformId }).then()
  }, [uniformId, getUniform, params.id])

  return (
    <>
      <UpdateUniformPageHeader />
      <UpdateUniformForm {...{ updateLoading, getLoading, getUniformResponse }} onSubmit={handleSubmitForm} />
    </>
  )
}

UpdateUniform.displayName = 'UpdateUniform'
export default UpdateUniform
