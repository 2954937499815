import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateAdminForm, UpdateAdminPageHeader } from '@/components/pages/Admin/Update'
import { UpdateAdminFormValue } from '@/components/pages/Admin/Update/Form'

import { useGetAdmin, useUpdateAdmins } from './Update.hooks'

const UpdateEmployee = () => {
  const params = useParams<{ id: string }>()
  const { updateAdmin, loadingUpdateAdmin } = useUpdateAdmins()
  const { getAdmin, loadingGetAdmin, responseGetAdmin } = useGetAdmin()
  const adminId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateAdminFormValue) => {
    await updateAdmin({
      ...formValues,
      id: adminId,
    })
  }

  useEffect(() => {
    getAdmin({ id: adminId }).then()
  }, [adminId, getAdmin, params.id])

  return (
    <>
      <UpdateAdminPageHeader />
      <UpdateAdminForm {...{ loadingUpdateAdmin, loadingGetAdmin, responseGetAdmin }} onSubmit={handleSubmitForm} />
    </>
  )
}

UpdateEmployee.displayName = 'UpdateEmployee'
export default UpdateEmployee
