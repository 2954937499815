import {
  CreateAdminRequest,
  CreateAdminResponse,
  DeleteAdminRequest,
  DeleteAdminResponse,
  GetAdminRequest,
  GetAdminResponse,
  GetAdminsRequest,
  GetAdminsResponse,
  UpdateAdminRequest,
  UpdateAdminResponse,
} from '@/types/apis/admins'
import { GetMeResponse } from '@/types/apis/admins/GetMeResponse'

import axios from './axios'

const API_ENDPOINT = '/users'

export const createAdmin = (request: CreateAdminRequest): Promise<CreateAdminResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const getAdmins = (request: GetAdminsRequest): Promise<GetAdminsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const getAdmin = (request: GetAdminRequest): Promise<GetAdminResponse> => {
  return axios.get(`${API_ENDPOINT}/${request.id}`)
}

export const getMe = (): Promise<GetMeResponse> => {
  return axios.get(`${API_ENDPOINT}/me`)
}

export const updateAdmin = (request: UpdateAdminRequest): Promise<UpdateAdminResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}`, request)
}

export const deleteAdmin = (request: DeleteAdminRequest): Promise<DeleteAdminResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}
