import './Layout.css'

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Image, Layout, Menu, Row, Space, Typography } from 'antd'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import jaJP from 'antd/lib/locale/ja_JP'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import japanFlag from '@/assets/images/flags/japan.png'
import usaFlag from '@/assets/images/flags/usa.png'
import Language from '@/constants/language'
import { useAuth } from '@/hooks/useAuth'
import useLanguage from '@/hooks/useLanguage'
import useMenu from '@/hooks/useMenu'

const { Header, Sider, Content } = Layout

const MainLayout: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [language, setLanguage] = useLanguage()
  const { pathname } = useLocation()

  const { logout } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const menus = useMenu()

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const selectedKey = '/' + pathname?.split('/')[1]
  const languages = {
    [Language.English]: { id: Language.English, name: t('locales.english'), flag: usaFlag },
    [Language.Japanese]: { id: Language.Japanese, name: t('locales.japanese'), flag: japanFlag },
  }

  const languageOverlay = (
    <Menu>
      {Object.values(languages).map((language) => (
        <Menu.Item key={language.id} onClick={() => setLanguage(language.id)}>
          <Image preview={false} width={30} height={30} src={language.flag} />
          <span style={{ marginLeft: 10 }}>{language.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <ConfigProvider locale={language === 'en' ? enUS : jaJP}>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu theme="dark" mode="inline" activeKey={selectedKey} selectedKeys={[selectedKey]}>
            {menus.map((menu) => {
              if (menu.isDisabled) {
                return null
              }
              return menu.children ? (
                menu.children.map((subMenu) => (
                  <Menu.SubMenu
                    onTitleClick={() => navigate(menu.link)}
                    title={menu.name}
                    key={menu.link}
                    icon={menu.icon}
                  >
                    <Menu.Item key={subMenu.link} icon={subMenu.icon}>
                      <Link to={subMenu.link}>{subMenu.name}</Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                ))
              ) : (
                <Menu.Item key={menu.link} icon={menu.icon}>
                  <Link to={menu.link}>{menu.name}</Link>
                </Menu.Item>
              )
            })}
          </Menu>
          <div style={{ padding: 10 }}>
            <Button type="primary" danger block onClick={logout}>
              {t('components.layout.logOut')}
            </Button>
          </div>
          <Space
            style={{ width: 100 + '%', position: 'absolute', bottom: 20, justifyContent: 'center', cursor: 'pointer' }}
            align="center"
            size="middle"
          >
            <Dropdown overlay={languageOverlay} trigger={['click']}>
              <div>
                <Image preview={false} width={30} height={30} src={languages[language].flag} />
                <span style={{ color: 'white', marginLeft: 10 }}>{languages[language].name}</span>
              </div>
            </Dropdown>
          </Space>
        </Sider>

        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: '0 20px 0 0' }}>
            <Row justify="space-between">
              <Col flex="auto">
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: toggle,
                })}
              </Col>
              <Col>
                <Typography.Title style={{ display: 'inline', marginRight: 10 }} level={4}>
                  Uniform Matching System
                </Typography.Title>
              </Col>
            </Row>
          </Header>

          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

MainLayout.displayName = 'MainLayout'
export default MainLayout
