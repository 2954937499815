import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { createAnnoucement } from '@/apis/announcements'
import routes from '@/constants/routes'
import useNotification from '@/hooks/useNotification'
import { CreateAnnouncementRequest, CreateAnnouncementResponse } from '@/types/apis/announcements'

export const useCreateAnnouncements = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<CreateAnnouncementResponse>()

  const create = useCallback(
    async ({
      text,
      startAt,
      endAt,
      isQuestion,
      isPublic,
      employeeIds,
      translations,
      genderFilter,
      languageFilter,
    }: CreateAnnouncementRequest) => {
      try {
        setLoading(true)
        const res = await createAnnoucement({
          text,
          startAt,
          endAt,
          isQuestion,
          isPublic,
          employeeIds,
          translations,
          genderFilter,
          languageFilter,
        })
        setResponse(res)
        openNotification({ description: t('pages.announcements.create.createSuccessfully') })
        navigate(routes.announcements.path)
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )

  return {
    loading,
    createAnnouncement: create,
    response,
  }
}
