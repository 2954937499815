export const list = {
  table: {
    code: '社員CD',
    name: '氏名',
    kana: 'カナ',
    gender: '性別',
    uid: 'ICカードNo.',
    action: 'アクション',
    language: '言語',
    companyJoinDate: '入社年月日',
    dob: '生年月日',
    update: '編集',
    delete: '削除',
    confirmDelete: '社員情報を削除しますか?',
  },
  pageHeader: {
    title: '社員情報',
    new: '新規',
    csvImport: 'CSV インポート',
  },
  languages: {
    en: '英語',
    vi: 'ベトナム語',
    th: 'タイ語',
    ja: '日本語',
    cn: '中国語',
  },
}
