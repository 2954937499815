import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'
const AnnouncementsPageHeader = () => {
  const navigate = useNavigate()
  const { hasPermission } = useAuth()
  const { t } = useTranslation()

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate(routes.announcementsImport.path)}>
        {t('components.pages.announcements.list.pageHeader.csvImport')}
      </Menu.Item>
    </Menu>
  )
  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.announcements.list.pageHeader.title')}
      extra={[
        hasPermission('annoucement/create') ? (
          <Dropdown.Button
            key="action"
            type="primary"
            icon={<DownOutlined />}
            overlay={menu}
            onClick={() => navigate(routes.createAnnouncement.path)}
          >
            {t('components.pages.announcements.list.pageHeader.new')}
          </Dropdown.Button>
        ) : null,
      ]}
    />
  )
}

AnnouncementsPageHeader.displayName = 'AnnouncementsPageHeader'
export default AnnouncementsPageHeader
