import { Modal, Select } from 'antd'
import { parse } from 'papaparse'
import { useCallback, useEffect, useMemo, useState, VFC } from 'react'
import { useTranslation } from 'react-i18next'

import { ImportUniformsPageHeader, ImportUniformsUploadCvs } from '@/components/pages/Uniforms/Import'
import { useImportUniforms } from '@/pages/Uniforms/Import/Import.hooks'

const ImportUniforms: VFC = () => {
  const { importUniforms, loading } = useImportUniforms()
  const [showsModal, setShowsModal] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [rows, setRows] = useState<Record<string, string>[]>([])
  const [mapping, setMapping] = useState<Record<string, string>>({})
  const [encoding, setEncoding] = useState('shift-jis')
  const [t] = useTranslation()

  const handleFileSelected = (data: FormData) => {
    const file = data.get('file') as File
    setFile(file)
  }

  const headers = useMemo(() => {
    const result = new Set<string>()
    rows.forEach((row) => {
      Object.keys(row).forEach((key) => result.add(key))
    })
    return Array.from(result)
  }, [rows])

  const handleChange = (header: string) => (value: string) => {
    // remove old header
    setMapping({
      ...mapping,
      [value]: header,
    })
  }

  const handleConfirm = useCallback(() => {
    if (!file) return
    const formData = new FormData()
    formData.append('file', file)
    formData.append('mapping', JSON.stringify(mapping))
    formData.append('encoding', encoding)
    importUniforms(formData)
  }, [encoding, file, importUniforms, mapping])

  const handleChangeEncoding = useCallback((value: string) => {
    setEncoding(value)
  }, [])

  useEffect(() => {
    if (!file) return
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () => {
      const result = parse<Record<string, string>>(fileReader.result as string, { header: true })
      setRows(result.data)
      setShowsModal(true)
      setMapping({})
    })
    fileReader.readAsText(file, encoding)
  }, [encoding, file])

  return (
    <>
      <ImportUniformsPageHeader />
      <ImportUniformsUploadCvs loading={loading} onUpload={handleFileSelected} />
      {file && (
        <Modal
          title="Confirm import employees"
          visible={showsModal}
          width={1000}
          onCancel={() => {
            setShowsModal(false)
            setFile(null)
          }}
          onOk={handleConfirm}
        >
          <Select defaultValue="" onChange={handleChangeEncoding} value={encoding}>
            <Select.Option value="utf-8">UTF-8</Select.Option>
            <Select.Option value="shift-jis">Shift-JIS</Select.Option>
          </Select>
          <table>
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header}>
                    {header}
                    <br />
                    <Select defaultValue="" onChange={handleChange(header)}>
                      <Select.Option value="">{t('pages.uniforms.importFromCsv.select')}</Select.Option>
                      <Select.Option value="productCode">{t('pages.uniforms.importFromCsv.productCode')}</Select.Option>
                      <Select.Option value="productName">{t('pages.uniforms.importFromCsv.productName')}</Select.Option>
                      <Select.Option value="code">{t('pages.uniforms.importFromCsv.icChipNo')}</Select.Option>
                      <Select.Option value="barcode">{t('pages.uniforms.importFromCsv.barcode')}</Select.Option>
                      <Select.Option value="size">{t('pages.uniforms.importFromCsv.size')}</Select.Option>
                    </Select>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.slice(0, 5).map((row, index) => (
                <tr key={index}>
                  {headers.map((header, index) => (
                    <td key={index}>{row[header]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </>
  )
}

ImportUniforms.displayName = 'ImportUniforms'
export default ImportUniforms
