import React from 'react'

import { CreateIdCardForm, CreateIdCardPageHeader } from '@/components/pages/IdCards/Create'
import { useCreateIdCards } from '@/pages/IdCards/Create/Create.hooks'

const CreateIdCard = () => {
  const { createIdCard, loading } = useCreateIdCards()

  return (
    <>
      <CreateIdCardPageHeader />
      <CreateIdCardForm {...{ loading }} onSubmit={createIdCard} />
    </>
  )
}

CreateIdCard.displayName = 'CreateIdCard'
export default CreateIdCard
