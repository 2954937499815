export const list = {
  table: {
    id: 'ID',
    text: '和訳',
    startAt: '開始日',
    endAt: '終了日',
    displayTarget: '表示対象',
    special: '特別',
    public: '全体',
    single: '個人',
    multiple: 'グループ',
    published: '公開',
    question: '質問のタイプ',
    action: 'アクション',
    update: '編集',
    delete: '削除',
    confirmDelete: 'このお知らせを削除しますか。',
    happyBirthday: 'お誕生日',
    joiningCompany: '入社日',
    titleModal: '通知でタグ付けされた従業員のリスト',
  },
  pageHeader: {
    title: 'メッセージ一覧',
    new: '新規',
    csvImport: 'CSV インポート',
  },
}
