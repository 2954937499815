import React from 'react'

import { Admins, CreateAdmin, UpdateAdmin } from '@/pages/Admin'
import { AnnouncementImport, Announcements, CreateAnnouncement, UpdateAnnouncement } from '@/pages/Announcements'
import { AttendanceLogs } from '@/pages/AttendanceLogs'
import ExportHistory from '@/pages/AttendanceLogs/ExportHistory/ExportHistory'
import { CreateEmployee, Employees, EmployeesImport, UpdateEmployee } from '@/pages/Employees'
import { Home } from '@/pages/Home'
import { CreateIdCard, IdCards, IdCardsImport } from '@/pages/IdCards'
import { CreateLanguage, Languages, UpdateLanguage } from '@/pages/Languages'
import { Login } from '@/pages/Login'
import { CreateTerminal, Terminals, UpdateTerminal } from '@/pages/Terminals'
import { CreateUniform, Uniforms, UniformsImport, UpdateUniform } from '@/pages/Uniforms'
import { UserPermission } from '@/types/apis/admins/GetMeResponse'

const EMPLOYEE_PATH = '/employees'
const ID_CARD_PATH = '/id-cards'
const UNIFORM_PATH = '/uniforms'
const TERMINAL_PATH = '/terminals'
const ANNOUNCEMENT_PATH = '/announcements'
const LANGUAGES_PATH = '/languages'
const LOGIN_PATH = '/login'
const ADMIN_PATH = '/users'

export type Route = {
  path: string
  component: React.FC
  isPrivate: boolean
  permission?: UserPermission
}

const routes: Record<string, Route> = {
  login: {
    path: LOGIN_PATH,
    component: Login,
    isPrivate: false,
  },
  admins: {
    path: ADMIN_PATH,
    component: Admins,
    isPrivate: true,
    permission: 'admin/view',
  },
  createAdmin: {
    path: `${ADMIN_PATH}/create`,
    component: CreateAdmin,
    isPrivate: true,
  },
  attendanceLogs: {
    path: '/attendance-logs',
    component: AttendanceLogs,
    isPrivate: true,
  },
  attendanceLogsExportHistory: {
    path: '/attendance-logs/export-history',
    component: ExportHistory,
    isPrivate: true,
  },
  updateAdmin: {
    path: `${ADMIN_PATH}/:id`,
    component: UpdateAdmin,
    isPrivate: true,
    permission: 'admin/update',
  },
  employees: {
    path: EMPLOYEE_PATH,
    component: Employees,
    isPrivate: true,
  },
  updateEmployee: {
    path: `${EMPLOYEE_PATH}/:id`,
    component: UpdateEmployee,
    isPrivate: true,
    permission: 'employee/update',
  },
  createEmployee: {
    path: `${EMPLOYEE_PATH}/create`,
    component: CreateEmployee,
    isPrivate: true,
    permission: 'employee/create',
  },
  employeesImport: {
    path: `${EMPLOYEE_PATH}/import`,
    component: EmployeesImport,
    isPrivate: true,
  },
  home: {
    path: '/',
    component: Home,
    isPrivate: true,
  },
  idCards: {
    path: ID_CARD_PATH,
    component: IdCards,
    isPrivate: true,
  },
  createIdCard: {
    path: `${ID_CARD_PATH}/create`,
    component: CreateIdCard,
    isPrivate: true,
  },
  announcements: {
    path: ANNOUNCEMENT_PATH,
    component: Announcements,
    isPrivate: true,
  },
  updateAnnouncement: {
    path: `${ANNOUNCEMENT_PATH}/:id`,
    component: UpdateAnnouncement,
    isPrivate: true,
    permission: 'annoucement/update',
  },
  createAnnouncement: {
    path: `${ANNOUNCEMENT_PATH}/create`,
    component: CreateAnnouncement,
    isPrivate: true,
    permission: 'annoucement/create',
  },
  announcementsImport: {
    path: `${ANNOUNCEMENT_PATH}/import`,
    component: AnnouncementImport,
    isPrivate: true,
  },
  idCardsImport: {
    path: `${ID_CARD_PATH}/import`,
    component: IdCardsImport,
    isPrivate: true,
  },
  languages: {
    path: LANGUAGES_PATH,
    component: Languages,
    isPrivate: true,
  },
  updateLanguage: {
    path: `${LANGUAGES_PATH}/:id`,
    component: UpdateLanguage,
    isPrivate: true,
    permission: 'language/update',
  },
  createLanguage: {
    path: `${LANGUAGES_PATH}/create`,
    component: CreateLanguage,
    isPrivate: true,
    permission: 'language/create',
  },
  terminals: {
    path: TERMINAL_PATH,
    component: Terminals,
    isPrivate: true,
  },
  createTerminal: {
    path: `${TERMINAL_PATH}/create`,
    component: CreateTerminal,
    isPrivate: true,
  },
  updateTerminal: {
    path: `${TERMINAL_PATH}/:id`,
    component: UpdateTerminal,
    isPrivate: true,
  },
  uniforms: {
    path: UNIFORM_PATH,
    component: Uniforms,
    isPrivate: true,
  },
  createUniform: {
    path: `${UNIFORM_PATH}/create`,
    component: CreateUniform,
    isPrivate: true,
  },
  uniformsImport: {
    path: `${UNIFORM_PATH}/import`,
    component: UniformsImport,
    isPrivate: true,
  },
  updateUniform: {
    path: `${UNIFORM_PATH}/:id`,
    component: UpdateUniform,
    isPrivate: true,
  },
}

export default routes
