import { Card, PageHeader } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'
import useMenu from '@/hooks/useMenu'

const Home = () => {
  const { t } = useTranslation()
  const menus = useMenu()
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  return (
    <>
      <PageHeader ghost={false} title={t('components.pages.home.pageHeader.title')} />
      {currentUser && <p>{t('components.pages.home.welcome', { name: currentUser.lastName })}</p>}
      <Card>
        {menus
          .filter((item) => item.link !== routes.home.path)
          .map((item) => (
            <Card.Grid key={item.link} onClick={() => navigate(item.link)} style={{ cursor: 'pointer' }}>
              {item.icon} {item.name}
            </Card.Grid>
          ))}
      </Card>
    </>
  )
}

Home.displayName = 'Home'
export default Home
