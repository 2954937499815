import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateLanguageForm, UpdateLanguagePageHeader } from '@/components/pages/Languages/Update'
import { UpdateLanguageFormValue } from '@/components/pages/Languages/Update/Form'

import { useGetLanguage, useUpdateLanguage } from './Update.hooks'

const CreateLanguage = () => {
  const params = useParams<{ id: string }>()
  const { updateLanguage, loading: updateLoading } = useUpdateLanguage()
  const { getLanguage, loading: getLoading, response: getLanguageResponse } = useGetLanguage()

  const languageId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateLanguageFormValue) => {
    await updateLanguage({ ...formValues, id: languageId })
  }

  useEffect(() => {
    getLanguage({ id: languageId }).then()
  }, [languageId, getLanguage, params.id])
  return (
    <>
      <UpdateLanguagePageHeader />
      <UpdateLanguageForm {...{ updateLoading, getLoading, getLanguageResponse }} onSubmit={handleSubmitForm} />
    </>
  )
}

CreateLanguage.displayName = 'CreateLanguage'
export default CreateLanguage
