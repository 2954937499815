export const update = {
  form: {
    firstName: 'First name',
    lastName: 'Last name',
    firstNameKana: 'First name (Kana)',
    lastNameKana: 'Last name (Kana)',
    userId: 'Users Id',
    userType: 'Permission',
    update: 'Update',
    requireFirstName: 'Please input admin first name!',
    requireLastName: 'Please input admin last name!',
    requireFirstNameKana: 'Please input admin first name (kana)!',
    requireLastNameKana: 'Please input admin last name (kana)!',
    requireUserId: 'Please input user Id!',
    requireUserType: 'Please input permission',
    select: 'Select',
    userTypeA: 'Manager A',
    userTypeB: 'Manager B',
  },
  pageHeader: {
    title: 'Update Admin',
    back: 'Back',
  },
}
