import { Button } from 'antd'
import { FC, useEffect, useMemo, useState } from 'react'

import useQuery from '@/hooks/useQuery'

type Props = {
  onChange: (selectedChars: string[]) => void
}

const KanaSearch: FC<Props> = ({ onChange }) => {
  const characters = useMemo(() => ['ア', 'カ', 'サ', 'タ', 'ナ', 'ハ', 'マ', 'ヤ', 'ラ', 'ワ'], [])
  const [selected, setSelected] = useState<Set<string>>(new Set())
  const query = useQuery()

  const toggleChar = (char: string) => {
    const isSelected = selected.has(char)
    if (isSelected) {
      selected.delete(char)
    } else {
      selected.clear()
      selected.add(char)
    }
    setSelected(new Set(selected))
  }

  useEffect(() => {
    onChange(Array.from(selected))
  }, [selected]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const kanaCharacters = query.get('kanaCharacters')?.split(',')
    if (kanaCharacters) {
      setSelected(new Set(kanaCharacters))
    }
  }, [characters, query])

  return (
    <div>
      {characters.map((char) => (
        <Button key={char} type={selected.has(char) ? 'link' : 'text'} size="small" onClick={() => toggleChar(char)}>
          {char}
        </Button>
      ))}
    </div>
  )
}

KanaSearch.displayName = 'KanaSearch'

export default KanaSearch
