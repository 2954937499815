import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CreateTerminalPageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.terminals.create.pageHeader.title')} />
}

CreateTerminalPageHeader.displayName = 'CreateTerminalPageHeader'
export default CreateTerminalPageHeader
