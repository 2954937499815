import { Button, Form, Input, Switch } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateLanguageRequest } from '@/types/apis/languages/CreateLanguageRequest'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateLanguageRequest) => void
}

const CreateLanguageForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.languages.create.form.name')}
        name="name"
        rules={[{ required: true, message: t('components.pages.languages.create.form.requireName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.languages.create.form.code')}
        name="code"
        rules={[{ required: true, message: t('components.pages.languages.create.form.requireCode') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={t('components.pages.languages.create.form.isDefault')} valuePropName="checked" name="isDefault">
        <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.languages.create.form.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateLanguageForm.displayName = 'CreateLanguageForm'
export default CreateLanguageForm
