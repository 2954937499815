import React from 'react'

import { CreateLanguageForm, CreateLanguagePageHeader } from '@/components/pages/Languages/Create'

import { useCreateLanguage } from './Create.hooks'

const CreateLanguage = () => {
  const { createLanguage, loading } = useCreateLanguage()

  return (
    <>
      <CreateLanguagePageHeader />
      <CreateLanguageForm {...{ loading }} onSubmit={createLanguage} />
    </>
  )
}

CreateLanguage.displayName = 'CreateLanguage'
export default CreateLanguage
