import { useEffect } from 'react'

import { CreateAdminForm, CreateAdminPageHeader } from '@/components/pages/Admin/Create'
import { useGetLanguages } from '@/pages/Announcements/Update/Update.hooks'

import { useCreateAdmin } from './Create.hooks'
const CreateAdmin = () => {
  const { createAdmin, loading } = useCreateAdmin()
  const { getLanguages, getLanguagesResponse } = useGetLanguages()

  useEffect(() => {
    getLanguages().then()
  }, [getLanguages])

  return (
    <>
      <CreateAdminPageHeader />
      <CreateAdminForm {...{ loading, getLanguagesResponse }} onSubmit={createAdmin} />
    </>
  )
}

CreateAdmin.displayName = 'CreateAdmin'
export default CreateAdmin
