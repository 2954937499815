import { Button, Popconfirm, Space, Table, TablePaginationConfig, Tag } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AttendanceLog } from '@/types'

type Props = {
  dataSource: AttendanceLog[] | undefined
  getAttendanceLogsLoading: boolean
  deleteAttendanceLogLoading: boolean
  page: number
  pageSize: number
  totalItems: number
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: AttendanceLog['id']) => void
}

const AttendanceLogsTable = ({
  dataSource,
  getAttendanceLogsLoading,
  deleteAttendanceLogLoading,
  page,
  pageSize,
  totalItems,
  onChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})

  const columns = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      render: (text: string, record: AttendanceLog) => {
        switch (record.type) {
          case 'checkIn':
            return <Tag color="success">{t('components.pages.attendanceLogs.list.table.checkIn')}</Tag>
          case 'checkOut':
            return <Tag color="blue">{t('components.pages.attendanceLogs.list.table.checkOut')}</Tag>
          case 'takeOut':
            return <Tag color="red">{t('components.pages.attendanceLogs.list.table.takeOut')}</Tag>
          default:
            return null
        }
      },
    },
    {
      title: t('components.pages.attendanceLogs.list.table.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: AttendanceLog) => moment(record.createdAt).format('L LT'),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.productCode'),
      dataIndex: 'productCode',
      key: 'productCode',
    },
    {
      title: t('components.pages.attendanceLogs.list.table.productName'),
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: t('components.pages.attendanceLogs.list.table.size'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: t('components.pages.attendanceLogs.list.table.employee'),
      dataIndex: 'employee',
      key: 'employee',
      render: (text: string, record: AttendanceLog) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {record.lastName} {record.firstName}
        </span>
      ),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.employeeCode'),
      dataIndex: 'employeeCode',
      key: 'employeeCode',
    },
    {
      title: t('components.pages.attendanceLogs.list.table.barcode'),
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: t('components.pages.attendanceLogs.list.table.uniform'),
      dataIndex: 'uniform',
      key: 'uniform',
      render: (text: string, record: AttendanceLog) => record.uniformCode,
    },
    {
      title: t('components.pages.attendanceLogs.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: AttendanceLog) => {
        return (
          <Space size="middle">
            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.attendanceLogs.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleConfirmDelete({})}
            >
              <Button
                type="link"
                size="small"
                danger
                onClick={() => setVisibleConfirmDelete({ [record.id]: true })}
                loading={deleteAttendanceLogLoading}
              >
                {t('components.pages.attendanceLogs.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getAttendanceLogsLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

AttendanceLogsTable.displayName = 'AttendanceLogsTable'
export default AttendanceLogsTable
