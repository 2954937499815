export const list = {
  table: {
    id: 'ID',
    code: 'Code',
    employee: '氏名',
    terminal: 'Terminal',
    uniform: 'ICチップNo.',
    createdAt: '時間',
    returnedAt: '解除時間',
    action: 'アクション',
    delete: '削除',
    confirmDelete: 'このアイテムを削除しますか?',
    type: 'タイプ',
    checkIn: '紐付',
    checkOut: '解除',
    takeOut: '持出し',
    productCode: '商品CD',
    productName: '商品名',
    size: 'サイズ',
    employeeCode: '社員CD',
    barcode: 'バーコードNo.',
  },
  pageHeader: {
    title: 'マッチングデータ',
  },
  filter: {
    select: '選択',
    matching: '紐付',
    return: '解除',
    takeOut: '持出し',
  },
}
