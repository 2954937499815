import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GetAdminResponse, UpdateAdminRequest } from '@/types/apis/admins'

import { UserTypeModal } from '../UserTypeModal/UserTypeModal'

export type UpdateAdminFormValue = Omit<UpdateAdminRequest, 'id'>

type Props = {
  loadingUpdateAdmin: boolean
  loadingGetAdmin: boolean
  responseGetAdmin: GetAdminResponse | undefined
  onSubmit: (formValues: UpdateAdminFormValue) => void
}

const UpdateAdminForm = ({ loadingUpdateAdmin, loadingGetAdmin, responseGetAdmin, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showUserTypeModal = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const [form] = Form.useForm<UpdateAdminFormValue>()

  useEffect(() => {
    if (responseGetAdmin) {
      form.setFieldsValue({
        ...responseGetAdmin,
      })
    }
  }, [form, responseGetAdmin])

  return (
    <Spin spinning={loadingGetAdmin}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.admins.update.form.lastName')}
          name="lastName"
          rules={[{ required: true, message: t('components.pages.admins.update.form.requireLastName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.admins.update.form.firstName')}
          name="firstName"
          rules={[{ required: true, message: t('components.pages.admins.update.form.requireFirstName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.admins.update.form.lastNameKana')}
          name="lastNameKana"
          rules={[{ required: true, message: t('components.pages.admins.update.form.requireLastNameKana') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.admins.update.form.firstNameKana')}
          name="firstNameKana"
          rules={[{ required: true, message: t('components.pages.admins.update.form.requireFirstNameKana') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.admins.update.form.userId')}
          name="userId"
          rules={[{ required: true, message: t('components.pages.admins.update.form.requireUserId') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t('components.pages.admins.create.form.userType')}{' '}
              <a href="#" onClick={showUserTypeModal}>
                <QuestionCircleOutlined />
              </a>
            </span>
          }
          name="userType"
          rules={[{ required: true, message: t('components.pages.admins.create.form.requireUserType') }]}
        >
          <Select allowClear>
            <Select.Option value="A">{t('components.pages.admins.create.form.all')}</Select.Option>
            <Select.Option value="B">{t('components.pages.admins.create.form.partial')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingUpdateAdmin}>
            {t('components.pages.admins.update.form.update')}
          </Button>
        </Form.Item>
        <UserTypeModal handleOk={handleOk} isVisible={isModalVisible} />
      </Form>
    </Spin>
  )
}

UpdateAdminForm.displayName = 'UpdateAdminForm'
export default UpdateAdminForm
