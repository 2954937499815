export const list = {
  pageHeader: {
    title: 'ログイン',
  },
  form: {
    requireName: 'ユーザー名を入力してください！',
    requirePassword: 'パスワードを入力してください！',
    placeholderInput: 'ユーザー名',
    placeholderPassWord: 'パスワード',
    btnLogin: 'ログイン',
  },
}
