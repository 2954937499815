export const create = {
  form: {
    name: '言語',
    code: 'コード',
    isDefault: 'デフォルト？',
    submit: '作成する',
    requireName: '言語は必須です',
    requireCode: 'コードは必須です',
  },
  pageHeader: {
    title: '言語作成',
    back: '戻る',
  },
}
