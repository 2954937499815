export const create = {
  form: {
    firstName: 'First name',
    lastName: 'Last name',
    firstNameKana: 'First name (Kana)',
    lastNameKana: 'Last name (Kana)',
    userId: 'User Id',
    password: 'Password',
    userType: 'Permission',
    requireFirstName: 'Please input admin first name!',
    requireLastName: 'Please input admin last name!',
    requireFirstNameKana: 'Please input admin first name (Kana)!',
    requireLastNameKana: 'Please input admin last lame (Kana)!',
    requireUserId: 'Please input UserId!',
    requirePassword: 'Please input password!',
    summit: 'Submit',
    all: 'Full control',
    partial: 'Read-only',
  },
  pageHeader: {
    title: 'Create Admin',
    back: 'Back',
  },
}
