import { Button, Checkbox, DatePicker, Form, Input, Select, Spin, Switch } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { dateTime } from '@/configs'
import { GetAnnouncementResponse, UpdateAnnouncementRequest } from '@/types/apis/announcements'
import { GetEmployeesResponse } from '@/types/apis/employees'
import { GetLanguagesResponse } from '@/types/apis/languages'
import { getName } from '@/utils/employee'

type LanguageCode = string

export type UpdateAnnouncementFormValue = Pick<
  UpdateAnnouncementRequest,
  | 'text'
  | 'startAt'
  | 'endAt'
  | 'isPublic'
  | 'isQuestion'
  | 'employeeIds'
  | 'translations'
  | 'specialType'
  | 'genderFilter'
  | 'languageFilter'
> & {
  language: Record<LanguageCode, string>
}

type Props = {
  updateLoading: boolean
  getLoading: boolean
  getAnnouncementResponse: GetAnnouncementResponse | undefined
  getEmployeesResponse: GetEmployeesResponse | undefined
  getLanguagesResponse: GetLanguagesResponse | undefined
  onSubmit: (formValues: UpdateAnnouncementFormValue) => void
}

const UpdateAnnouncementForm = ({
  updateLoading,
  getLoading,
  getAnnouncementResponse,
  getEmployeesResponse,
  getLanguagesResponse,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isEmployeeIdsRequired, setIsEmployeeIdsRequired] = useState(false)
  const dataSourceEmployees = getEmployeesResponse?.items ?? []
  const dataEmployees = dataSourceEmployees.map((item) => ({ label: getName(item), value: item?.id }))

  const dataSourceLanguages = useMemo(() => getLanguagesResponse ?? [], [getLanguagesResponse])
  const languageInputs = useMemo(
    () =>
      dataSourceLanguages.map((item, index) => (
        <div key={index}>
          <Form.Item label={item.name} name={['translations', index, 'id']} noStyle>
            {/* NOTE: Add Input to remove warning in browser */}
            <Input hidden />
          </Form.Item>
          <Form.Item label={item.name} name={['translations', index, 'text']}>
            <Input />
          </Form.Item>
          <Form.Item label={item.name} name={['translations', index, 'language']} noStyle>
            {/* NOTE: Add Input to remove warning in browser */}
            <Input hidden />
          </Form.Item>
        </div>
      )),
    [dataSourceLanguages]
  )

  const genderOptions = [
    { label: t('components.pages.announcements.create.form.male'), value: 'male' },
    { label: t('components.pages.announcements.create.form.female'), value: 'female' },
  ]
  const languageFilterOptions = dataSourceLanguages.map((item) => ({ label: item.name, value: item.code }))
  const handleSubmit = async (values: UpdateAnnouncementFormValue) => {
    try {
      if (!values.isPublic && !values.specialType && !values.genderFilter?.length && !values.languageFilter?.length) {
        setIsEmployeeIdsRequired(true)
        await form.validateFields()
      } else {
        setIsEmployeeIdsRequired(false)
      }
      onSubmit(values)
    } catch {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (getAnnouncementResponse) {
      const currentEmployees = getAnnouncementResponse.employees.map((item) => item.id)
      form.setFieldsValue({
        text: getAnnouncementResponse.text,
        startAt: getAnnouncementResponse.startAt ? moment(getAnnouncementResponse.startAt) : undefined,
        endAt: getAnnouncementResponse.endAt ? moment(getAnnouncementResponse.endAt) : undefined,
        employeeIds: currentEmployees,
        isPublic: getAnnouncementResponse.isPublic,
        specialType: getAnnouncementResponse.specialType,
        genderFilter: getAnnouncementResponse.genderFilter ?? [],
        languageFilter: getAnnouncementResponse.languageFilter ?? [],
      })

      if (dataSourceLanguages.length) {
        const currentTranslationsResponse = getAnnouncementResponse.translations ?? []

        // NOTE: Translations announcement array is always contained all languages of language response
        const newTranslationArray = dataSourceLanguages.map((language) => {
          const currentTranslation = currentTranslationsResponse.find(
            (translate) => translate.language.code === language.code
          )

          if (currentTranslation) {
            return currentTranslation
          }

          return { id: null, text: '', language }
        })

        form.setFieldsValue({ translations: newTranslationArray })
      }
    }
  }, [dataSourceLanguages, form, getAnnouncementResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item
          label={t('components.pages.announcements.update.form.text')}
          name="text"
          rules={[{ required: true, message: t('components.pages.announcements.update.form.requireText') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.announcements.update.form.public')}
          name="isPublic"
          valuePropName="checked"
        >
          <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
        </Form.Item>

        <Form.Item label={t('components.pages.announcements.update.form.specialType')} name="specialType">
          <Select placeholder={t('components.pages.announcements.update.form.select')} allowClear>
            <Select.Option value="happyBirthday">
              {t('components.pages.announcements.update.form.happyBirthday')}
            </Select.Option>
            <Select.Option value="joiningCompany">
              {t('components.pages.announcements.update.form.joiningCompany')}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={t('components.pages.announcements.create.form.targetFilter')}>
          <Form.Item name="genderFilter">
            <Checkbox.Group options={genderOptions} />
          </Form.Item>
          <Form.Item name="languageFilter">
            <Checkbox.Group options={languageFilterOptions} />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={t('components.pages.announcements.update.form.employees')}
          name="employeeIds"
          rules={[
            {
              required: isEmployeeIdsRequired,
              message: t('components.pages.announcements.update.form.requireEmployeeIds'),
            },
          ]}
        >
          <Select
            options={dataEmployees}
            mode="multiple"
            placeholder={t('components.pages.announcements.update.form.requireSelect')}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item label={t('components.pages.announcements.update.form.startAt')} name="startAt">
          <DatePicker format={dateTime.dateFormat} />
        </Form.Item>
        <Form.Item label={t('components.pages.announcements.update.form.endAt')} name="endAt">
          <DatePicker format={dateTime.dateFormat} />
        </Form.Item>

        <h3>{t('components.pages.announcements.update.form.translations')}</h3>
        {languageInputs}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            {t('components.pages.announcements.update.form.update')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateAnnouncementForm.displayName = 'UpdateAnnouncementForm'
export default UpdateAnnouncementForm
