export const importFromCsv = {
  importModalTitle: 'インポートデータ確認',
  importSuccessfully: '通知がインポートされました。',
  importFailed: 'アナウンスのインポートに失敗しました',
  select: '選択',
  text: '和訳',
  startAt: '開始日',
  endAt: '終了日',
  displayTarget: '表示対象',
  ja: '日本語',
  en: '英語',
  cn: '中国語',
  th: 'タイ語',
  vi: 'ベトナム語',
}
