import React, { useEffect } from 'react'

import { CreateEmployeeForm, CreateEmployeePageHeader } from '@/components/pages/Employees/Create'
import { useCreateEmployees } from '@/pages/Employees/Create/Create.hooks'

import { useGetLanguages } from '../Update/Update.hooks'

const CreateEmployee = () => {
  const { createEmployee, loading } = useCreateEmployees()
  const { getLanguages, getLanguagesResponse } = useGetLanguages()

  useEffect(() => {
    getLanguages().then()
  }, [getLanguages])

  return (
    <>
      <CreateEmployeePageHeader />
      <CreateEmployeeForm {...{ loading, getLanguagesResponse }} onSubmit={createEmployee} />
    </>
  )
}

CreateEmployee.displayName = 'CreateEmployee'
export default CreateEmployee
