export const list = {
  table: {
    id: 'ID',
    name: 'Name',
    description: 'Description',
    action: 'Action',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this terminal?',
  },
  pageHeader: {
    title: 'Terminals',
    new: 'New',
  },
}
