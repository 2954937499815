import {
  DownloadOutlined,
  GlobalOutlined,
  HomeOutlined,
  IdcardOutlined,
  NotificationOutlined,
  SettingOutlined,
  SkinOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import routes from '@/constants/routes'

import { useAuth } from './useAuth'

const useMenu = () => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()

  const menus = [
    { name: t('components.layout.dashBoard'), icon: <HomeOutlined />, link: routes.home.path },
    {
      name: t('components.layout.attendanceLogs'),
      icon: <UserSwitchOutlined />,
      link: routes.attendanceLogs.path,
      children: [
        {
          name: t('components.layout.exportHistory'),
          icon: <DownloadOutlined />,
          link: routes.attendanceLogsExportHistory.path,
        },
      ],
    },
    {
      name: t('components.layout.announcements'),
      icon: <NotificationOutlined />,
      link: routes.announcements.path,
    },
    // NOTE: remove this menu for now, ID card code is a property of employee now
    // { name: t('components.layout.idCards'), icon: <IdcardOutlined />, link: routes.idCards.path },
    // { name: t('components.layout.terminals'), icon: <MacCommandOutlined />, link: routes.terminals.path },

    { name: t('components.layout.employees'), icon: <UserOutlined />, link: routes.employees.path },
    { name: t('components.layout.uniforms'), icon: <SkinOutlined />, link: routes.uniforms.path },
    {
      name: t('components.layout.admins'),
      icon: <IdcardOutlined />,
      link: routes.admins.path,
      isDisabled: !hasPermission('admin/view'),
    },
    {
      name: t('components.layout.settings'),
      icon: <SettingOutlined />,
      link: routes.languages.path,
      children: [
        {
          name: t('components.layout.languages'),
          icon: <GlobalOutlined />,
          link: routes.languages.path,
        },
      ],
    },
  ]
  return menus
}

export default useMenu
