import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateEmployeeForm, UpdateEmployeePageHeader } from '@/components/pages/Employees/Update'
import { UpdateEmployeeFormValue } from '@/components/pages/Employees/Update/Form'
import { useEmployees } from '@/pages/Employees/Employees.hooks'
import { useGetEmployee, useGetLanguages, useUpdateEmployees } from '@/pages/Employees/Update/Update.hooks'
import { Employee } from '@/types'

const UpdateEmployee = () => {
  const params = useParams<{ id: string }>()
  const { updateEmployee, loading: updateLoading } = useUpdateEmployees()
  const { getEmployee, loading: getLoading, response: getEmployeeResponse } = useGetEmployee()
  const { getLanguages, getLanguagesResponse } = useGetLanguages()
  const { deleteEmployee } = useEmployees()
  const employeeId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateEmployeeFormValue) => {
    const { dob, companyJoinDate } = formValues
    await updateEmployee({
      ...formValues,
      id: employeeId,
      dob: dob?.toDate(),
      companyJoinDate: companyJoinDate?.toDate(),
    })
  }
  const handleDeleteEmployee = async (id: Employee['id']) => {
    await deleteEmployee({ id })
  }

  useEffect(() => {
    getEmployee({ id: employeeId }).then()
    getLanguages().then()
  }, [employeeId, getEmployee, getLanguages, params.id])

  return (
    <>
      <UpdateEmployeePageHeader />
      <UpdateEmployeeForm
        {...{ updateLoading, getLoading, getEmployeeResponse, getLanguagesResponse, employeeId }}
        onSubmit={handleSubmitForm}
        onDelete={handleDeleteEmployee}
      />
    </>
  )
}

UpdateEmployee.displayName = 'UpdateEmployee'
export default UpdateEmployee
