import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { createAdmin } from '@/apis/admins'
import useNotification from '@/hooks/useNotification'
import { CreateAdminRequest, CreateAdminResponse } from '@/types/apis/admins'

export const useCreateAdmin = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<CreateAdminResponse>()
  const navigate = useNavigate()
  const openNotification = useNotification()
  const { t } = useTranslation()
  const create = useCallback(
    async (request: CreateAdminRequest) => {
      try {
        setLoading(true)
        const res = await createAdmin(request)
        setResponse(res)
        openNotification({ description: t('pages.admins.create.createSuccessfully') })
        navigate(-1)
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )
  return {
    loading,
    createAdmin: create,
    response,
  }
}
