import { Modal, TablePaginationConfig } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AnnouncementsPageHeader, AnnouncementsTable } from '@/components/pages/Announcements/List'
import routes from '@/constants/routes'
import { Announcement, Employee } from '@/types'
import { getName } from '@/utils/employee'

import { useAnnouncements } from './Announcements.hook'
const Announcements = () => {
  const navigate = useNavigate()
  const {
    getAnnouncements,
    deleteAnnouncement,
    deleteAnnouncementLoading,
    getAnnouncementsLoading,
    getAnnouncementsResponse,
    page,
    pageSize,
    setPage,
  } = useAnnouncements()

  const dataSource = getAnnouncementsResponse?.items ?? []
  const totalItems = getAnnouncementsResponse?.meta.totalItems || 0
  const [data, setdata] = useState<Employee[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { t } = useTranslation()
  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.announcements.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  const handleDeleteAnnouncement = async (id: Announcement['id']) => {
    await deleteAnnouncement({ id })
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handelGetData = (value: Employee[]) => {
    setdata(value)
  }
  useEffect(() => {
    getAnnouncements().then()
  }, [getAnnouncements])

  return (
    <>
      <AnnouncementsPageHeader />
      <AnnouncementsTable
        {...{
          dataSource,
          deleteAnnouncement,
          deleteAnnouncementLoading,
          getAnnouncementsLoading,
          page,
          pageSize,
          totalItems,
          setIsModalVisible,
        }}
        onChange={handleChangePage}
        onDelete={handleDeleteAnnouncement}
        onGetData={handelGetData}
      />
      <Modal
        title={t('components.pages.announcements.list.table.titleModal')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {data?.map((item) => {
          return <div key={item.code}>{getName(item)}</div>
        })}
      </Modal>
    </>
  )
}

Announcements.displayName = 'Announcements'
export default Announcements
