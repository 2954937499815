import React, { FC, useEffect, useState } from 'react'

import { getMe } from '@/apis/admins'
import axios from '@/apis/axios'
import { AUTH_KEY } from '@/constants/storage'
import { GetMeResponse, UserPermission } from '@/types/apis/admins/GetMeResponse'
import { LoginResponse } from '@/types/apis/auth'
import * as localStorage from '@/utils/localStorage'

type AuthContextProps = {
  accessToken: string | undefined
  currentUser?: GetMeResponse
  setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>
  hasPermission: (permission: UserPermission) => boolean
  initiated: boolean
}

export const AuthContext = React.createContext<AuthContextProps>({
  accessToken: '',
  currentUser: undefined,
  setAccessToken: () => {
    // do nothing
  },
  hasPermission: () => false,
  initiated: false,
})

export const AuthProvider: FC = ({ children }) => {
  const auth = localStorage.get<LoginResponse>(AUTH_KEY)
  const [accessToken, setAccessToken] = useState(auth?.accessToken)
  const [currentUser, setCurrentUser] = useState<GetMeResponse>()
  const [initiated, setInitiated] = useState(false)

  const hasPermission = (permission: UserPermission): boolean => {
    return currentUser?.permissions.includes(permission) ?? false
  }

  useEffect(() => {
    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      getMe().then((user) => setCurrentUser(user))
    } else {
      axios.defaults.headers.common['Authorization'] = ``
    }
    setInitiated(true)
  }, [accessToken])

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken, currentUser, hasPermission, initiated }}>
      {initiated ? children : null}
    </AuthContext.Provider>
  )
}
AuthProvider.displayName = 'AuthProvider'
export default AuthProvider
