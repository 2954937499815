import i18n from '@/configs/i18n'
import { Employee } from '@/types'

export const getName = (employee: Employee) => {
  return `${employee.lastName} ${employee.firstName}`
}

export const getNameKana = (employee: Employee) => {
  return `${employee.lastNameKana} ${employee.firstNameKana}`
}

export const getGenderText = (employee: Employee) => {
  switch (employee.gender) {
    case 0:
      return i18n.t('common.male')
    case 1:
      return i18n.t('common.female')
    default:
      return ''
  }
}
