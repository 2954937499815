import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import { CreateUniformResponse } from '@/types/apis/uniforms'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateUniformResponse) => void
}

const CreateUniformForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.uniforms.create.form.code')}
        name="code"
        rules={[{ required: true, message: t('components.pages.uniforms.create.form.requireCode') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.uniforms.create.form.name')}
        name="name"
        rules={[{ required: true, message: t('components.pages.uniforms.create.form.requireName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.uniforms.create.form.size')}
        name="size"
        rules={[{ required: true, message: t('components.pages.uniforms.create.form.requireSize') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.uniforms.create.form.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateUniformForm.displayName = 'CreateUniformForm'
export default CreateUniformForm
