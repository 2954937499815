import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, PageHeader, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useBack } from '@/hooks/useBack'

const UpdateAdminPageHeader = () => {
  const { t } = useTranslation()
  const handleBack = useBack()
  return (
    <Space>
      <Button onClick={handleBack} icon={<ArrowLeftOutlined />}>
        {t('components.pages.admins.update.pageHeader.back')}
      </Button>
      <PageHeader ghost={false} title={t('components.pages.admins.update.pageHeader.title')} />
    </Space>
  )
}

UpdateAdminPageHeader.displayName = 'UpdateAdminPageHeader'
export default UpdateAdminPageHeader
