import { Route, Routes as RoutesWrapper } from 'react-router-dom'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'

import PrivateRoute from './PrivateRoute'
const Routes = () => {
  const { hasPermission } = useAuth()
  return (
    <RoutesWrapper>
      {Object.values(routes).map((route, index) => {
        if (route.isPrivate) {
          if (!route.permission || hasPermission(route.permission)) {
            return (
              <Route key={index.toString()} path={route.path} element={<PrivateRoute component={route.component} />} />
            )
          }
          return null
        }
        return <Route key={index.toString()} path={route.path} element={<route.component />} />
      })}
    </RoutesWrapper>
  )
}

Routes.displayName = 'Routes'
export default Routes
