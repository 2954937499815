import {
  CreateTerminalRequest,
  CreateTerminalResponse,
  DeleteTerminalRequest,
  DeleteTerminalResponse,
  GetTerminalRequest,
  GetTerminalResponse,
  GetTerminalsRequest,
  GetTerminalsResponse,
  UpdateTerminalRequest,
  UpdateTerminalResponse,
} from '@/types/apis/terminals'

import axios from './axios'
const API_ENDPOINT = '/terminals'

export const getTerminals = (request: GetTerminalsRequest): Promise<GetTerminalsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const createTerminal = (request: CreateTerminalRequest): Promise<CreateTerminalResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const deleteTerminal = (request: DeleteTerminalRequest): Promise<DeleteTerminalResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const getTerminal = (request: GetTerminalRequest): Promise<GetTerminalResponse> => {
  return axios.get(`${API_ENDPOINT}/${request.id}`)
}

export const updateTerminal = (request: UpdateTerminalRequest): Promise<UpdateTerminalResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}`, request)
}
