export const update = {
  form: {
    name: 'Name',
    description: 'Description',
    update: 'Update',
    requireName: 'Please input terminal name!',
    requireDescription: 'Please input terminal description!',
  },
  pageHeader: {
    title: 'Update terminal',
  },
}
