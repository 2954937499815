import { Button, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IdCard } from '@/types'

type Props = {
  dataSource: IdCard[] | undefined
  page: number
  pageSize: number
  totalItems: number
  getIdCardLoading: boolean
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: IdCard['id']) => void
}

const IdCardsTable = ({ dataSource, getIdCardLoading, page, pageSize, totalItems, onChange, onDelete }: Props) => {
  const { t } = useTranslation()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})
  const columns = [
    {
      title: t('components.pages.idCards.list.form.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.idCards.list.form.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('components.pages.idCards.list.form.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: IdCard) => (
        <Space size="middle">
          <Popconfirm
            id={record.id.toString()}
            visible={!!visibleConfirmDelete[record.id]}
            title={t('components.pages.idCards.list.form.confirmDelete')}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => onDelete(record.id)}
            onCancel={() => setVisibleConfirmDelete({})}
          >
            <Button type="link" size="small" danger onClick={() => setVisibleConfirmDelete({ [record.id]: true })}>
              {t('components.pages.idCards.list.form.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={getIdCardLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
      rowKey="id"
    />
  )
}

IdCardsTable.displayName = 'IdCardsTable'
export default IdCardsTable
