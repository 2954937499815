import { Button, PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'

const AdminsPageHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.admins.list.pageHeader.title')}
      extra={[
        <Button key="action" type="primary" onClick={() => navigate(routes.createAdmin.path)}>
          {t('components.pages.admins.list.pageHeader.new')}
        </Button>,
      ]}
    />
  )
}

AdminsPageHeader.displayName = 'AdminsPageHeader'
export default AdminsPageHeader
