import { Button, Form, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { UpdateTerminalRequest, UpdateTerminalResponse } from '@/types/apis/terminals'

export type UpdateTerminalFormValue = Pick<UpdateTerminalRequest, 'name' | 'description'>

type Props = {
  updateLoading: boolean
  getLoading: boolean
  getTerminalResponse: UpdateTerminalResponse | undefined
  onSubmit: (formValues: UpdateTerminalFormValue) => void
}

const UpdateTerminalForm = ({ updateLoading, getLoading, getTerminalResponse, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (getTerminalResponse) {
      form.setFieldsValue({
        name: getTerminalResponse.name,
        description: getTerminalResponse.description,
      })
    }
  }, [form, getTerminalResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.terminals.update.form.name')}
          name="name"
          rules={[{ required: true, message: t('components.pages.terminals.update.form.requireName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.terminals.update.form.description')}
          name="description"
          rules={[{ required: true, message: t('components.pages.terminals.update.form.requireDescription') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            {t('components.pages.terminals.update.form.update')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateTerminalForm.displayName = 'UpdateTerminalForm'
export default UpdateTerminalForm
