import { DownloadOutlined } from '@ant-design/icons'
import { Button, Space, Table, TablePaginationConfig } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { apiHost } from '@/configs/api'
import { AttendanceLogExport } from '@/types'

type Props = {
  dataSource: AttendanceLogExport[]
  loading: boolean
  page: number
  pageSize: number
  totalItems: number
  onChange: (config: TablePaginationConfig) => void
}

const AttendanceLogExportHistoryTable = ({ dataSource, loading, page, pageSize, totalItems, onChange }: Props) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('pages.attendanceLogs.exportHistory.table.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: AttendanceLogExport) => {
        return moment(record.createdAt).format('LLL')
      },
    },
    {
      title: t('pages.attendanceLogs.exportHistory.table.fileName'),
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: t('pages.attendanceLogs.exportHistory.table.size'),
      dataIndex: 'size',
      key: 'size',
      render: (text: string, record: AttendanceLogExport) => {
        if (record.size > 1e6) {
          return record.size / 1e6 + 'MB'
        } else if (record.size > 1e3) {
          return Math.ceil(record.size / 1e3) + 'KB'
        } else {
          return Math.ceil(record.size) + 'B'
        }
      },
    },
    {
      title: t('pages.attendanceLogs.exportHistory.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: AttendanceLogExport) => {
        return (
          <Space size="middle">
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              size="middle"
              onClick={() => window.open(`${apiHost}${record.url}`, '_blank')}
            />
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

AttendanceLogExportHistoryTable.displayName = 'AttendanceLogExportHistoryTable'
export default AttendanceLogExportHistoryTable
