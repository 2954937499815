export const importFromCsv = {
  importModalTitle: 'インポートデータ確認',
  importSuccessfully: '社員情報はインポート完了しました。',
  importFailed: '社員情報インポートは失敗しました。',
  select: '選択',
  name: '氏名',
  code: '社員CD',
  uid: 'UID',
  firstName: '名',
  lastName: '姓',
  firstNameKana: '名 (カナ)',
  lastNameKana: '姓 (カナ)',
  gender: '性別',
  companyJoinDate: '入社日',
  dob: '生年月日',
  language: '言語',
}
