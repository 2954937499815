import { admins } from './Admin/Admin.i18n.ja'
import { announcements } from './Announcements/Announcements.i18n.ja'
import { attendanceLogs } from './AttendanceLogs/AttendanceLogs.i18n.ja'
import { employees } from './Employees/Employees.i18n.ja'
import { home } from './Home/Home.i18n.ja'
import { idCards } from './IdCards/IdCards.i18n.ja'
import { languages } from './Languages/Languages.i18n.ja'
import { login } from './Login/Login.i18n.ja'
import { terminals } from './Terminals/Terminals.i18n.ja'
import { uniforms } from './Uniforms/Uniforms.i18n.ja'

export const pages = {
  announcements,
  attendanceLogs,
  employees,
  idCards,
  languages,
  terminals,
  uniforms,
  login,
  admins,
  home,
}
