import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Language from '@/constants/language'
import { LANGUAGE_KEY } from '@/constants/storage'
import * as localStorage from '@/utils/localStorage'

export type LanguageContextProps = [language: Language, setLanguage: (language: Language) => Promise<void>]

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const LanguageContext = React.createContext<LanguageContextProps>([Language.English, async () => {}])

const LanguageContextProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation()

  const currentLanguage = (localStorage.get(LANGUAGE_KEY) || Language.English) as Language
  const [language, setLanguage] = useState<Language>(currentLanguage)

  const handleSetLanguage = async (lang: Language) => {
    localStorage.set(LANGUAGE_KEY, lang)
    setLanguage(lang)
    await i18n.changeLanguage(lang)
  }

  return <LanguageContext.Provider value={[language, handleSetLanguage]}>{children}</LanguageContext.Provider>
}

LanguageContextProvider.displayName = 'LanguageContextProvider'
export default LanguageContextProvider
