export const update = {
  form: {
    firstName: '名',
    lastName: '姓',
    firstNameKana: '名（カナ）',
    lastNameKana: '姓（カナ）',
    userId: 'ユーザーID',
    userType: '権限',
    update: '保存する',
    requireFirstName: '名は必須です',
    requireLastName: '姓は必須です',
    requireFirstNameKana: '名（カナ）は必須です',
    requireLastNameKana: '姓（カナ）は必須です',
    requireUserId: 'ユーザーIDを入力してください！',
    requireUserType: '権限は必須です',
    select: '選択',
    userTypeA: '管理者A',
    userTypeB: '管理者B',
  },
  pageHeader: {
    title: '社員情報',
    back: '戻る',
  },
}
