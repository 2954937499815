export const update = {
  form: {
    text: 'Text',
    startAt: 'Start At',
    endAt: 'End At',
    question: 'Question',
    public: 'For all employees',
    translations: 'Translations',
    employees: 'Employees',
    update: 'Update',
    requireText: 'Please input announcement Text field!',
    requireStartAt: 'Please input announcement Start At field!',
    requireEndAt: 'Please input announcement End At field!',
    requireEmployeeIds: 'Please select target employees',
    updateSuccessfully: 'Update announcement successfully!',
    requireSelect: 'Please select',
    specialType: 'Special type',
    select: 'Select',
    happyBirthday: 'Birthday message',
    joiningCompany: 'Joining company message',
    targetFilter: 'Target filter',
    male: 'Male',
    female: 'Female',
  },
  pageHeader: {
    title: 'Update Announcement',
    back: 'Back',
  },
}
