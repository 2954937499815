export const list = {
  table: {
    id: 'No.',
    firstName: 'First Name',
    lastName: 'Last Name',
    firstNameKana: 'First Name Kana',
    lastNameKana: 'Last Name Kana',
    userId: 'User Id',
    userType: 'Permission',
    update: 'Update',
    delete: 'Delete',
    action: 'Action',
    confirmDelete: 'Are you sure to delete this admin?',
    all: 'Full control',
    partial: 'Read-only',
  },
  pageHeader: {
    title: 'Admins',
    new: 'New',
  },
}
