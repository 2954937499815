import qs from 'qs'
import { useCallback, useEffect, useState } from 'react'

import { deleteEmployee, getEmployees } from '@/apis/employees'
import { API_LIMIT } from '@/constants/api'
import useQuery from '@/hooks/useQuery'
import useQueryPage from '@/hooks/useQueryPage'
import { DeleteEmployeeRequest, GetEmployeesResponse } from '@/types/apis/employees'

export interface IEmployeeFilter {
  kanaCharacters?: string[]
}

export type PageQueryParams = {
  kanaCharacters?: string
  page?: number
}

export const useEmployees = () => {
  const currentPage = useQueryPage()
  const query = useQuery()
  const [page, setPage] = useState(currentPage)
  const [getEmployeesLoading, setGetEmployeesLoading] = useState(false)
  const [deleteEmployeeLoading, setDeleteEmployeeLoading] = useState(false)
  const [getEmployeesResponse, setEmployeesResponse] = useState<GetEmployeesResponse>()
  const [filter, setFilter] = useState<IEmployeeFilter>({ kanaCharacters: [] })

  const filterToQuery = (filter: IEmployeeFilter & { page?: number }): string => {
    const filterQuery: PageQueryParams = {}

    if (filter.kanaCharacters) {
      filterQuery.kanaCharacters = filter.kanaCharacters.join(',')
    }

    if (filter.page) {
      filterQuery.page = filter.page
    }

    return qs.stringify(filterQuery)
  }

  const get = useCallback(async () => {
    try {
      setGetEmployeesLoading(true)
      const res = await getEmployees({
        page,
        limit: API_LIMIT,
        kanaCharacters: filter.kanaCharacters?.join(','),
      })
      setEmployeesResponse(res)
    } finally {
      setGetEmployeesLoading(false)
    }
  }, [filter, page])

  const del = useCallback(
    async ({ id }: DeleteEmployeeRequest) => {
      try {
        setDeleteEmployeeLoading(true)
        await deleteEmployee({ id })

        if (getEmployeesResponse?.items) {
          const newEmployees = getEmployeesResponse.items.filter((item) => item.id !== id)
          setEmployeesResponse({ ...getEmployeesResponse, items: newEmployees })
        }
      } finally {
        setDeleteEmployeeLoading(false)
      }
    },
    [getEmployeesResponse]
  )

  useEffect(() => {
    const kanaCharacters = query.get('kanaCharacters')
    const page = query.get('page')

    const filterParams: IEmployeeFilter = {
      kanaCharacters: kanaCharacters?.split(','),
    }

    setFilter(filterParams)
    setPage(page ? Number(page) : 1)
  }, [query])

  return {
    getEmployeesLoading,
    deleteEmployeeLoading,
    getEmployees: get,
    deleteEmployee: del,
    page,
    pageSize: API_LIMIT,
    setPage,
    getEmployeesResponse,
    setFilter,
    filter,
    filterToQuery,
  }
}
