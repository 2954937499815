import { useCallback, useState } from 'react'

import { deleteIdCard, getIdCards } from '@/apis/idCards'
import { API_LIMIT } from '@/constants/api'
import useQueryPage from '@/hooks/useQueryPage'
import { DeleteIdCardRequest, GetIdCardsResponse } from '@/types/apis/idCards'

export const useIdCards = () => {
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [getIdCardLoading, setGetIdCardLoading] = useState(false)
  const [deleteIdCardLoading, setDeleteIdCardLoading] = useState(false)
  const [getIdCardResponse, setIdCardResponse] = useState<GetIdCardsResponse>()

  const get = useCallback(async () => {
    try {
      setGetIdCardLoading(true)
      const res = await getIdCards({ page, limit: API_LIMIT })
      setIdCardResponse(res)
    } finally {
      setGetIdCardLoading(false)
    }
  }, [page])

  const del = useCallback(
    async ({ id }: DeleteIdCardRequest) => {
      try {
        setDeleteIdCardLoading(true)
        await deleteIdCard({ id })

        if (getIdCardResponse?.items) {
          const newIdCards = getIdCardResponse.items.filter((item) => item.id !== id)
          setIdCardResponse({ ...getIdCardResponse, items: newIdCards })
        }
      } finally {
        setDeleteIdCardLoading(false)
      }
    },
    [getIdCardResponse]
  )
  return {
    getIdCardLoading,
    deleteIdCardLoading,
    page,
    pageSize: API_LIMIT,
    setPage,
    getIdCardResponse,
    deleteIdCard: del,
    getIdCards: get,
  }
}
