import { Button, DatePicker, Form, Input, Popconfirm, Select, Space, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Employee } from '@/types'
import { GetEmployeeResponse, UpdateEmployeeRequest } from '@/types/apis/employees'
import { GetLanguagesResponse } from '@/types/apis/languages'

export type UpdateEmployeeFormValue = Omit<UpdateEmployeeRequest, 'dob' | 'companyJoinDate'> & {
  dob?: moment.Moment
  companyJoinDate?: moment.Moment
}

type Props = {
  employeeId: number
  updateLoading: boolean
  getLoading: boolean
  getEmployeeResponse: GetEmployeeResponse | undefined
  getLanguagesResponse: GetLanguagesResponse | undefined
  onSubmit: (formValues: UpdateEmployeeFormValue) => void
  onDelete: (id: Employee['id']) => void
}

const UpdateEmployeeForm = ({
  employeeId,
  updateLoading,
  getLoading,
  getEmployeeResponse,
  getLanguagesResponse,
  onSubmit,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<UpdateEmployeeFormValue>()
  const [visibleConfirmDelete, setVisibleComfirmDelete] = useState<Record<string, boolean>>({})
  const navigate = useNavigate()
  useEffect(() => {
    if (getEmployeeResponse) {
      form.setFieldsValue({
        ...getEmployeeResponse,
        dob: getEmployeeResponse.dob ? moment(getEmployeeResponse.dob) : undefined,
        companyJoinDate: getEmployeeResponse.companyJoinDate ? moment(getEmployeeResponse.companyJoinDate) : undefined,
      })
    }
  }, [form, getEmployeeResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.employees.update.form.code')}
          name="code"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireCode') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.uid')}
          name="idCardCode"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireUid') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.lastName')}
          name="lastName"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireLastName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.firstName')}
          name="firstName"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireFirstName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.lastNameKana')}
          name="lastNameKana"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireLastNameKana') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.firstNameKana')}
          name="firstNameKana"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireFirstNameKana') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.gender')}
          name="gender"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireGender') }]}
        >
          <Select placeholder="Select a option and change input text above" allowClear>
            <Select.Option value={0}>{t('components.pages.employees.update.form.genderMale')}</Select.Option>
            <Select.Option value={1}>{t('components.pages.employees.update.form.genderFemale')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.languageCode')}
          name="languageCode"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireLanguageCode') }]}
        >
          <Select placeholder="Select a option and change input text above" allowClear>
            {getLanguagesResponse?.map((language) => (
              <Select.Option key={language.id} value={language.code}>
                {language.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.companyJoinDate')}
          name="companyJoinDate"
          // rules={[{ required: true, message: t('components.pages.employees.update.form.requireCompanyJoinDate') }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.dob')}
          name="dob"
          // rules={[{ required: true, message: t('components.pages.employees.update.form.requireDob') }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" htmlType="submit" loading={updateLoading}>
              {t('components.pages.employees.update.form.update')}
            </Button>
            <Popconfirm
              id={employeeId.toString()}
              visible={!!visibleConfirmDelete[employeeId]}
              title={t('components.pages.announcements.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onCancel={() => setVisibleComfirmDelete({})}
              onConfirm={async () => {
                await onDelete(employeeId)
                navigate('/employees')
              }}
            >
              <Button danger onClick={() => setVisibleComfirmDelete({ [employeeId]: true })}>
                {t('components.pages.announcements.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateEmployeeForm.displayName = 'UpdateEmployeeForm'
export default UpdateEmployeeForm
