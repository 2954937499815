import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateAnnoucementForm, UpdateAnnouncementPageHeader } from '@/components/pages/Announcements/Update'
import { UpdateAnnouncementFormValue } from '@/components/pages/Announcements/Update/Form'
import { useGetAnnouncement, useUpdateAnnouncements } from '@/pages/Announcements/Update/Update.hooks'
import { useGetEmployees } from '@/pages/Announcements/Update/Update.hooks'
import { useGetLanguages } from '@/pages/Announcements/Update/Update.hooks'
const UpdateAnnouncement = () => {
  const params = useParams<{ id: string }>()
  const { updateAnnouncement, loading: updateLoading } = useUpdateAnnouncements()
  const { getAnnouncement, loading: getLoading, response: getAnnouncementResponse } = useGetAnnouncement()
  const { getEmployees, getEmployeesResponse } = useGetEmployees()
  const { getLanguages, getLanguagesResponse } = useGetLanguages()
  const announcementId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateAnnouncementFormValue) => {
    await updateAnnouncement({ ...formValues, id: announcementId })
  }

  useEffect(() => {
    getAnnouncement({ id: announcementId }).then()
  }, [announcementId, getAnnouncement, params.id])

  useEffect(() => {
    getEmployees().then()
    getLanguages().then()
  }, [getEmployees, getLanguages])

  return (
    <>
      <UpdateAnnouncementPageHeader />
      <UpdateAnnoucementForm
        {...{
          updateLoading,
          getLoading,
          getAnnouncementResponse,
          getEmployeesResponse,
          getLanguagesResponse,
        }}
        onSubmit={handleSubmitForm}
      />
    </>
  )
}

UpdateAnnouncement.displayName = 'UpdateAnnouncement'
export default UpdateAnnouncement
