import { useCallback, useState } from 'react'

import { deleteUniform, getUniforms } from '@/apis/uniforms'
import { API_LIMIT } from '@/constants/api'
import useQueryPage from '@/hooks/useQueryPage'
import { DeleteUniformRequest, GetUniformsResponse } from '@/types/apis/uniforms'

export const useUniforms = () => {
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [getUniformsLoading, setGetUniformsLoading] = useState(false)
  const [deleteUniformLoading, setDeleteUniformLoading] = useState(false)
  const [getUniformsResponse, setUniformsResponse] = useState<GetUniformsResponse>()

  const get = useCallback(async () => {
    try {
      setGetUniformsLoading(true)
      const res = await getUniforms({ page, limit: API_LIMIT })
      setUniformsResponse(res)
    } finally {
      setGetUniformsLoading(false)
    }
  }, [page])

  const del = useCallback(
    async ({ id }: DeleteUniformRequest) => {
      try {
        setDeleteUniformLoading(true)
        await deleteUniform({ id })

        if (getUniformsResponse) {
          const newUniforms = getUniformsResponse.items.filter((item) => item.id !== id)
          setUniformsResponse({ ...getUniformsResponse, items: newUniforms })
        }
      } finally {
        setDeleteUniformLoading(false)
      }
    },
    [getUniformsResponse]
  )

  return {
    getUniformsLoading,
    deleteUniformLoading,
    getUniforms: get,
    deleteUniform: del,
    getUniformsResponse,
    page,
    pageSize: API_LIMIT,
    setPage,
  }
}
