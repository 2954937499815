import { CreateUniformForm, CreateUniFormPageHeader } from '@/components/pages/Uniforms/Create'
import { useCreateUniforms } from '@/pages/Uniforms/Create/Create.hooks'

const CreateUniform = () => {
  const { createUniform, loading } = useCreateUniforms()

  return (
    <>
      <CreateUniFormPageHeader />
      <CreateUniformForm {...{ loading }} onSubmit={createUniform} />
    </>
  )
}

CreateUniform.displayName = 'CreateUniform'
export default CreateUniform
