import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LoginRequest } from '@/types/apis/auth'

export type LoginFormValue = Pick<LoginRequest, 'userId' | 'password'>

type Props = {
  loading: boolean
  onSubmit: (formValues: LoginFormValue) => void
}
const LoginForm = ({ onSubmit, loading }: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<LoginFormValue>()
  return (
    <Form form={form} onFinish={onSubmit} size="large" style={{ width: 300 }}>
      <Form.Item
        name="userId"
        rules={[
          {
            required: true,
            message: t('components.pages.login.list.form.requireName'),
          },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder={t('components.pages.login.list.form.placeholderInput')} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t('components.pages.login.list.form.requirePassword'),
          },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder={t('components.pages.login.list.form.placeholderPassword')}
        />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} style={{ width: 300 }} type="primary" htmlType="submit" className="login-form-button">
          {t('components.pages.login.list.form.btnLogin')}
        </Button>
      </Form.Item>
    </Form>
  )
}

LoginForm.displayName = 'LoginForm'
export default LoginForm
