export const list = {
  table: {
    code: 'ICチップNo.',
    productName: '商品名',
    size: 'サイズ',
    productCode: '商品CD',
    barcode: 'バーコードNo.',
  },
  pageHeader: {
    title: 'ユニフォーム情報',
  },
}
