import { Button, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'
import { Employee } from '@/types'
import { formatYMD } from '@/utils/date'
import { getGenderText, getName, getNameKana } from '@/utils/employee'

type Props = {
  dataSource: Employee[] | undefined
  getEmployeesLoading: boolean
  page: number
  pageSize: number
  totalItems: number
  deleteEmployeeLoading: boolean
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: Employee['id']) => void
}

const EmployeesTable = ({
  dataSource,
  getEmployeesLoading,
  deleteEmployeeLoading,
  page,
  pageSize,
  totalItems,
  onChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { hasPermission } = useAuth()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})

  const languages: Record<string, string> = {
    en: t('components.pages.employees.list.languages.en'),
    ja: t('components.pages.employees.list.languages.ja'),
    vi: t('components.pages.employees.list.languages.vi'),
    th: t('components.pages.employees.list.languages.th'),
    cn: t('components.pages.employees.list.languages.cn'),
  }

  const columns: ColumnsType<Employee> = [
    {
      title: t('components.pages.employees.list.table.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('components.pages.employees.list.table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: Employee) => {
        return getName(record)
      },
    },
    {
      title: t('components.pages.employees.list.table.kana'),
      dataIndex: 'kana',
      key: 'kana',
      render: (text: string, record: Employee) => {
        return getNameKana(record)
      },
    },
    {
      title: t('components.pages.employees.list.table.gender'),
      dataIndex: 'gender',
      key: 'gender',
      render: (text: string, record: Employee) => {
        return getGenderText(record)
      },
    },
    {
      title: t('components.pages.employees.list.table.uid'),
      dataIndex: 'idCardCode',
      key: 'idCardCode',
    },
    {
      title: t('components.pages.employees.list.table.language'),
      dataIndex: 'languageCode',
      key: 'language',
      render: (value: string) => {
        return languages[value] ?? value
      },
    },
    {
      title: t('components.pages.employees.list.table.companyJoinDate'),
      dataIndex: 'companyJoinDate',
      key: 'companyJoinDate',
      render: (text: string, record: Employee) => {
        return record.companyJoinDate ? formatYMD(record.companyJoinDate) : ''
      },
    },
    {
      title: t('components.pages.employees.list.table.dob'),
      dataIndex: 'dob',
      key: 'dob',
      render: (text: string, record: Employee) => {
        return record.dob ? formatYMD(record.dob) : ''
      },
    },
  ]

  if (hasPermission('employee/update')) {
    columns.push({
      title: t('components.pages.employees.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: Employee) => {
        return (
          <Space size="middle">
            <Button type="link" size="small" onClick={() => goToEditEmployeePage(record.id)}>
              {t('components.pages.admins.list.table.update')}
            </Button>
            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.attendanceLogs.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleConfirmDelete({})}
            >
              <Button
                type="link"
                size="small"
                danger
                onClick={() => setVisibleConfirmDelete({ [record.id]: true })}
                loading={deleteEmployeeLoading}
              >
                {t('components.pages.admins.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    })
  }

  const goToEditEmployeePage = (id: Employee['id']) => {
    navigate(`${routes.employees.path}/${id}`)
  }

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getEmployeesLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

EmployeesTable.displayName = 'EmployeesTable'
export default EmployeesTable
