export const layout = {
  dashBoard: 'TOP',
  employees: '社員情報',
  idCards: 'Idカード',
  terminals: 'ターミナル',
  uniforms: 'ユニフォーム情報',
  attendanceLogs: 'マッチングデータ',
  languages: '言語',
  announcements: 'メッセージ一覧',
  admins: '管理者情報',
  settings: '設定',
  exportHistory: '帳票出力履歴',
  logOut: 'ログアウト',
}
