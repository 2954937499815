import { GetAttendanceLogExportsRequest } from '@/types/apis/attendanceLogExports/GetAttendanceLogExportsRequest'
import { GetAttendanceLogExportsResponse } from '@/types/apis/attendanceLogExports/GetAttendanceLogExportsResponse'

import axios from './axios'

const API_ENDPOINT = '/attendance-log-exports'

export const getAttendanceLogExports = (
  request: GetAttendanceLogExportsRequest
): Promise<GetAttendanceLogExportsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}
