import {
  CreateUniformRequest,
  CreateUniformResponse,
  DeleteUniformRequest,
  DeleteUniformResponse,
  GetUniformRequest,
  GetUniformResponse,
  GetUniformsRequest,
  GetUniformsResponse,
  ImportUniformsFromCsvRequest,
  ImportUniformsFromCsvResponse,
  UpdateUniformRequest,
  UpdateUniformResponse,
} from '@/types/apis/uniforms'

import axios from './axios'

const API_ENDPOINT = '/uniforms'

export const createUniform = (request: CreateUniformRequest): Promise<CreateUniformResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const importUniformsFromCsv = (
  request: ImportUniformsFromCsvRequest
): Promise<ImportUniformsFromCsvResponse> => {
  return axios.post(`${API_ENDPOINT}/import-csv`, request)
}

export const getUniforms = (request: GetUniformsRequest): Promise<GetUniformsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const deleteUniform = (request: DeleteUniformRequest): Promise<DeleteUniformResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const getUniform = (request: GetUniformRequest): Promise<GetUniformResponse> => {
  return axios.get(`${API_ENDPOINT}/${request.id}`)
}

export const updateUniform = (request: UpdateUniformRequest): Promise<UpdateUniformResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}`, request)
}
