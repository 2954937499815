import { Button, PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'

const LanguagesPageHeader = () => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  const navigate = useNavigate()

  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.languages.list.pageHeader.title')}
      extra={[
        hasPermission('language/create') ? (
          <Button key="action" type="primary" onClick={() => navigate(routes.createLanguage.path)}>
            {t('components.pages.languages.list.pageHeader.new')}
          </Button>
        ) : null,
      ]}
    />
  )
}

LanguagesPageHeader.displayName = 'LanguagesPageHeader'
export default LanguagesPageHeader
