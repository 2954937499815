import { DatePicker, Select } from 'antd'
import { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import React from 'react'
import { useTranslation } from 'react-i18next'

import dateTime from '@/configs/dateTime'
import { LogType } from '@/types'

export interface IAttendanceLogFilter {
  dateRange?: RangeValue<Moment>
  logType?: LogType
  kanaCharacters?: string[]
}

type Props = {
  filter: IAttendanceLogFilter
  onChange: (filter: IAttendanceLogFilter) => void
}

const AttendanceLogFilter: React.FC<Props> = ({ filter, onChange }) => {
  const { Option } = Select
  const { t } = useTranslation()

  const handleChangeType = (type: LogType) => {
    onChange({ ...filter, logType: type })
  }

  const handleChangeDateRange = (dateRange: RangeValue<Moment>) => {
    onChange({ ...filter, dateRange })
  }

  return (
    <div>
      <DatePicker.RangePicker
        value={filter.dateRange}
        onChange={(dates) => handleChangeDateRange(dates)}
        style={{ marginRight: 5 }}
        format={dateTime.dateFormat}
      />
      <Select
        style={{ width: 120 }}
        value={filter.logType}
        placeholder={t('components.pages.attendanceLogs.list.filter.select')}
        onChange={(value) => handleChangeType(value)}
      >
        <Option value="checkIn">{t('components.pages.attendanceLogs.list.filter.matching')}</Option>
        <Option value="checkOut">{t('components.pages.attendanceLogs.list.filter.return')}</Option>
        <Option value="takeOut">{t('components.pages.attendanceLogs.list.filter.takeOut')}</Option>
      </Select>
    </div>
  )
}

AttendanceLogFilter.displayName = 'FilterEmployee'

export default AttendanceLogFilter
