export const create = {
  form: {
    name: 'Name',
    description: 'Description',
    submit: 'Submit',
    requireName: 'Please input terminal name!',
    requireDescription: 'Please input terminal description!',
  },
  pageHeader: {
    title: 'New Terminal',
  },
}
