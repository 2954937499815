import React from 'react'
import { Navigate } from 'react-router-dom'

import { Layout } from '@/components/Layout'
import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'
export type Prop = {
  component: React.FC
}
const PrivateRoute: React.VFC<Prop> = ({ component: Component }) => {
  const { authenticated } = useAuth()

  if (!authenticated) {
    return <Navigate to={routes.login.path} />
  }

  return (
    <Layout>
      <Component />
    </Layout>
  )
}

PrivateRoute.displayName = 'PrivateRoute'
export default PrivateRoute
