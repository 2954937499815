import { Button, Checkbox, DatePicker, Form, Input, Select, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { dateTime } from '@/configs'
import { CreateAnnouncementRequest } from '@/types/apis/announcements'
import { GetEmployeesResponse } from '@/types/apis/employees'
import { GetLanguagesResponse } from '@/types/apis/languages'
import { getName } from '@/utils/employee'

type Props = {
  loading: boolean
  getLanguagesResponse: GetLanguagesResponse | undefined
  getEmployeesResponse: GetEmployeesResponse | undefined
  onSubmit: (formValues: CreateAnnouncementRequest) => void
}

const CreateAnnouncementForm = ({ loading, onSubmit, getLanguagesResponse, getEmployeesResponse }: Props) => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [isEmployeeIdsRequired, setIsEmployeeIdsRequired] = useState(false)

  const dataSourceEmployees = getEmployeesResponse?.items ?? []
  const dataEmployees = dataSourceEmployees.map((item) => ({ label: getName(item), value: item?.id }))

  const dataSourceLanguages = useMemo(() => getLanguagesResponse ?? [], [getLanguagesResponse])
  const languageInputs = useMemo(
    () =>
      dataSourceLanguages.map((item, index) => (
        <div key={index}>
          <Form.Item label={item.name} name={['translations', index, 'id']} noStyle>
            {/* NOTE: Add Input to remove warning in browser */}
            <Input hidden />
          </Form.Item>
          <Form.Item label={item.name} name={['translations', index, 'text']}>
            <Input />
          </Form.Item>
          <Form.Item label={item.name} name={['translations', index, 'language']} initialValue={item} noStyle>
            {/* NOTE: Add Input to remove warning in browser */}
            <Input hidden />
          </Form.Item>
        </div>
      )),
    [dataSourceLanguages]
  )
  const genderOptions = [
    { label: t('components.pages.announcements.create.form.male'), value: 'male' },
    { label: t('components.pages.announcements.create.form.female'), value: 'female' },
  ]

  const languageFilterOptions = dataSourceLanguages.map((item) => ({ label: item.name, value: item.code }))

  const handleSubmit = async (values: CreateAnnouncementRequest) => {
    try {
      if (!values.isPublic && !values.genderFilter?.length && !values.languageFilter?.length) {
        setIsEmployeeIdsRequired(true)
        await form.validateFields()
      } else {
        setIsEmployeeIdsRequired(false)
      }
      onSubmit(values)
    } catch {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <Form.Item
        label={t('components.pages.announcements.create.form.text')}
        name="text"
        rules={[{ required: true, message: t('components.pages.announcements.create.form.requireText') }]}
      >
        <Input />
      </Form.Item>

      {/* TODO: fix i18n, currently using update form */}
      <Form.Item label={t('components.pages.announcements.update.form.public')} name="isPublic" valuePropName="checked">
        <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
      </Form.Item>

      <Form.Item label={t('components.pages.announcements.create.form.specialType')} name="specialType">
        <Select placeholder={t('components.pages.announcements.create.form.select')} allowClear>
          <Select.Option value="happyBirthday">
            {t('components.pages.announcements.create.form.happyBirthday')}
          </Select.Option>
          <Select.Option value="joiningCompany">
            {t('components.pages.announcements.create.form.joiningCompany')}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label={t('components.pages.announcements.create.form.targetFilter')}>
        <Form.Item name="genderFilter">
          <Checkbox.Group options={genderOptions} />
        </Form.Item>
        <Form.Item name="languageFilter">
          <Checkbox.Group options={languageFilterOptions} />
        </Form.Item>
      </Form.Item>

      {/* TODO: fix i18n, currently using update form */}
      <Form.Item
        label={t('components.pages.announcements.update.form.employees')}
        name="employeeIds"
        rules={[
          {
            required: isEmployeeIdsRequired,
            message: t('components.pages.announcements.create.form.requireEmployeeIds'),
          },
        ]}
      >
        <Select
          options={dataEmployees}
          mode="multiple"
          placeholder={t('components.pages.announcements.update.form.requireSelect')}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item label={t('components.pages.announcements.create.form.startAt')} name="startAt">
        <DatePicker format={dateTime.dateFormat} />
      </Form.Item>

      <Form.Item label={t('components.pages.announcements.create.form.endAt')} name="endAt">
        <DatePicker format={dateTime.dateFormat} />
      </Form.Item>

      <h3>{t('components.pages.announcements.create.form.translations')}</h3>
      {languageInputs}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.announcements.create.form.summit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateAnnouncementForm.displayName = 'CreateAnnouncementForm'
export default CreateAnnouncementForm
