export const list = {
  table: {
    code: 'IC Chip No.',
    productName: 'Product name',
    size: 'Size',
    productCode: 'Product code',
    barcode: 'Barcode No.',
  },
  pageHeader: {
    title: 'Uniforms',
  },
}
