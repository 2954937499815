import { Button, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { Admin } from '@/types'

type Props = {
  dataSource: Admin[] | undefined
  getAdminsLoading: boolean
  deleteAdminLoading: boolean
  page: number
  pageSize: number
  totalItems: number
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: Admin['id']) => void
}

const AdminsTable = ({
  dataSource,
  getAdminsLoading,
  deleteAdminLoading,
  page,
  pageSize,
  totalItems,
  onChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})

  const columns = [
    {
      title: t('components.pages.admins.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.admins.list.table.lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('components.pages.admins.list.table.firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('components.pages.admins.list.table.lastNameKana'),
      dataIndex: 'lastNameKana',
      key: 'lastNameKana',
    },
    {
      title: t('components.pages.admins.list.table.firstNameKana'),
      dataIndex: 'firstNameKana',
      key: 'firstNameKana',
    },
    {
      title: t('components.pages.admins.list.table.userId'),
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: t('components.pages.admins.list.table.userType'),
      dataIndex: 'userType',
      key: 'userType',
      render: (value: string) => {
        switch (value) {
          case 'A':
            return t('components.pages.admins.list.table.all')
          case 'B':
            return t('components.pages.admins.list.table.partial')
          default:
            return null
        }
      },
    },
    {
      title: t('components.pages.admins.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: Admin) => {
        return (
          <Space size="middle">
            <Button type="link" size="small" onClick={() => goToEditAdminPage(record.id)}>
              {t('components.pages.admins.list.table.update')}
            </Button>
            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.admins.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleConfirmDelete({})}
            >
              <Button
                type="link"
                size="small"
                danger
                onClick={() => setVisibleConfirmDelete({ [record.id]: true })}
                loading={deleteAdminLoading}
              >
                {t('components.pages.admins.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const goToEditAdminPage = (id: Admin['id']) => {
    navigate(`${routes.admins.path}/${id}`)
  }

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getAdminsLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

AdminsTable.displayName = 'AdminsTable'
export default AdminsTable
