import { VFC } from 'react'

import { ImportIdCardsPageHeader, ImportIdCardsUploadCvs } from '@/components/pages/IdCards/Import'
import { useImportIdCard } from '@/pages/IdCards/Import/Import.hooks'

const ImportIdCard: VFC = () => {
  const { importIdCard, loading } = useImportIdCard()

  return (
    <>
      <ImportIdCardsPageHeader />
      <ImportIdCardsUploadCvs loading={loading} onUpload={importIdCard} />
    </>
  )
}

ImportIdCard.displayName = 'ImportIdCard'
export default ImportIdCard
