import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { loginApi } from '@/apis/auth'
import axios from '@/apis/axios'
import { AuthContext } from '@/contexts/Auth.context'
import { LoginRequest } from '@/types/apis/auth'
import * as localStorage from '@/utils/localStorage'

import { AUTH_KEY } from './../constants/storage'
import useNotification from './useNotification'

export const useAuth = () => {
  const { accessToken, setAccessToken, currentUser, hasPermission } = useContext(AuthContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const openNotification = useNotification()

  const login = useCallback(
    async (request: LoginRequest) => {
      try {
        setLoading(true)
        const res = await loginApi(request)
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.accessToken}`
        setAccessToken(res.accessToken)
        localStorage.set(AUTH_KEY, res)
        openNotification({ description: t('pages.login.loginSuccessfully') })
      } catch (error) {
        openNotification({ description: t('pages.login.loginError'), type: 'error' })
      } finally {
        setLoading(false)
      }
    },
    [openNotification, setAccessToken, t]
  )

  const logout = useCallback(() => {
    setAccessToken(undefined)
    localStorage.set(AUTH_KEY, null)
  }, [setAccessToken])

  return {
    authenticated: !!accessToken,
    currentUser,
    hasPermission,
    login,
    logout,
    loading,
  }
}
