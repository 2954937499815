import { DownloadOutlined } from '@ant-design/icons'
import { Button, PageHeader, TablePaginationConfig, Tooltip } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AttendanceLogsTable } from '@/components/pages/AttendanceLogs/List'
import AttendanceLogFilter, { IAttendanceLogFilter } from '@/components/pages/AttendanceLogs/List/Filter'
import KanaSearch from '@/components/pages/Employees/List/KanaSearch'
import routes from '@/constants/routes'
import { AttendanceLog } from '@/types'

import { useAttendanceLogs } from './AttendanceLogs.hooks'

const AttendanceLogs = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {
    getAttendanceLogs,
    deleteAttendanceLog,
    getAttendanceLogsLoading,
    getAttendanceLogsResponse,
    deleteAttendanceLogLoading,
    page,
    pageSize,
    // setPage,
    filter,
    exportExcel,
    filterToQuery,
  } = useAttendanceLogs()

  const dataSource = getAttendanceLogsResponse?.items ?? []
  const totalItems = getAttendanceLogsResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    navigate({
      pathname: routes.attendanceLogs.path,
      search: filterToQuery({
        ...filter,
        page: config.current ?? page,
      }),
    })
  }

  const handleDeleteAttendanceLog = async (id: AttendanceLog['id']) => {
    await deleteAttendanceLog({ id })
  }

  const handleChangeFilter = (updatedFilter: IAttendanceLogFilter) => {
    const newFilter = {
      ...filter,
      ...updatedFilter,
      page,
    }
    // debugger
    navigate({
      pathname: '/attendance-logs',
      search: filterToQuery(newFilter),
    })
  }

  const handleChangeKanaSearch = (chars: string[]) => {
    navigate({
      pathname: '/attendance-logs',
      search: filterToQuery({
        ...filter,
        kanaCharacters: chars,
        page,
      }),
    })
  }

  const handleClickExport = () => {
    exportExcel(filterToQuery(filter))
  }

  useEffect(() => {
    getAttendanceLogs().then()
  }, [getAttendanceLogs])

  return (
    <>
      <PageHeader
        ghost={false}
        title={t('components.pages.attendanceLogs.list.pageHeader.title')}
        extra={[
          <KanaSearch key="kanaSearch" onChange={handleChangeKanaSearch} />,
          <AttendanceLogFilter key="filterDate" filter={filter} onChange={handleChangeFilter} />,
          // TODO: i18n for tooltip title
          <Tooltip key="export" placement="top" title="帳票出力">
            <Button type="primary" icon={<DownloadOutlined />} size="middle" onClick={handleClickExport} />
          </Tooltip>,
        ]}
      />
      <AttendanceLogsTable
        {...{ getAttendanceLogsLoading, page, pageSize, totalItems, dataSource, deleteAttendanceLogLoading }}
        onChange={handleChangePage}
        onDelete={handleDeleteAttendanceLog}
      />
    </>
  )
}

AttendanceLogs.displayName = 'AttendanceLogs'
export default AttendanceLogs
