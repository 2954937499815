import { Button, DatePicker, Form, Input, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateEmployeeRequest } from '@/types/apis/employees'
import { GetLanguagesResponse } from '@/types/apis/languages'

type Props = {
  loading: boolean
  getLanguagesResponse?: GetLanguagesResponse
  onSubmit: (formValues: CreateEmployeeRequest) => void
}

const CreateEmployeeForm = ({ loading, getLanguagesResponse, onSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.employees.create.form.code')}
        name="code"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireCode') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.uid')}
        name="idCardCode"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireUid') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.lastName')}
        name="lastName"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireLastName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.firstName')}
        name="firstName"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireFirstName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.lastNameKana')}
        name="lastNameKana"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireLastNameKana') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.firstNameKana')}
        name="firstNameKana"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireFirstNameKana') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.gender')}
        name="gender"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireGender') }]}
      >
        <Select placeholder={t('components.pages.employees.create.form.select')} allowClear>
          <Select.Option value={0}>{t('components.pages.employees.create.form.genderMale')}</Select.Option>
          <Select.Option value={1}>{t('components.pages.employees.create.form.genderFemale')}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.languageCode')}
        name="languageCode"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireLanguageCode') }]}
      >
        <Select placeholder={t('components.pages.employees.create.form.select')} allowClear>
          {getLanguagesResponse?.map((language) => (
            <Select.Option key={language.id} value={language.code}>
              {language.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.companyJoinDate')}
        name="companyJoinDate"
        // rules={[{ required: true, message: t('components.pages.employees.create.form.requireCompanyJoinDate') }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.dob')}
        name="dob"
        // rules={[{ required: true, message: t('components.pages.employees.update.form.requireDob') }]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.employees.create.form.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateEmployeeForm.displayName = 'CreateEmployeeForm'
export default CreateEmployeeForm
