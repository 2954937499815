export const layout = {
  dashBoard: 'Home',
  employees: 'Employees',
  idCards: 'Id Cards',
  terminals: 'Terminals',
  uniforms: 'Uniforms',
  attendanceLogs: 'Matching data',
  languages: 'Languages',
  announcements: 'Messages',
  admins: 'Admins',
  settings: 'Settings',
  exportHistory: 'Export history',
  logOut: 'Log out',
}
