import { PageHeader, TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import useQueryPage from '@/hooks/useQueryPage'

import { useAttendanceLogExports } from './ExportHistory.hook'
import AttendanceLogExportHistoryTable from './Table'

const ExportHistory = () => {
  const currentPage = useQueryPage()
  const navigate = useNavigate()
  const { getExports, loading, response, page, pageSize, setPage } = useAttendanceLogExports()
  const { t } = useTranslation()

  const dataSource = response?.items ?? []
  const totalItems = response?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.attendanceLogsExportHistory.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  useEffect(() => {
    if (currentPage) {
      setPage(currentPage)
    }
  }, [currentPage, setPage])

  useEffect(() => {
    getExports().then()
  }, [getExports])

  return (
    <>
      <PageHeader ghost={false} title={t('pages.attendanceLogs.exportHistory.pageHeader.title')} />
      <AttendanceLogExportHistoryTable
        {...{ dataSource, loading, page, totalItems, pageSize }}
        onChange={handleChangePage}
      />
    </>
  )
}

ExportHistory.displayName = 'Home'
export default ExportHistory
