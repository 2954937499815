export const list = {
  table: {
    id: 'ID',
    code: 'Code',
    employee: 'Name',
    terminal: 'Terminal',
    uniform: 'IC Chip No.',
    createdAt: 'Created At',
    returnedAt: 'Returned At',
    action: 'Action',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this matching data?',
    type: 'Type',
    checkIn: 'Matching',
    checkOut: 'Return',
    takeOut: 'Take out',
    productCode: 'Product code',
    productName: 'Product name',
    size: 'Size',
    employeeCode: 'Employee Code',
    barcode: 'Barcode',
  },
  pageHeader: {
    title: 'Matching data',
  },
  filter: {
    select: 'Select',
    matching: 'Matching',
    return: 'Return',
    takeOut: 'Take Out',
  },
}
