export const create = {
  form: {
    firstName: '名',
    lastName: '姓',
    firstNameKana: '名（カナ）',
    lastNameKana: '姓（カナ）',
    userId: 'ユーザーID',
    password: 'パスワード',
    userType: '権限',
    requireFirstName: '名は必須です。',
    requireLastName: '姓は必須です。',
    requireFirstNameKana: '名（カナ）は必須です。',
    requireLastNameKana: '姓（カナ）は必須です。',
    requireUserId: 'ユーザーIDは必須です。',
    requirePassword: 'パスワードは必須です。',
    summit: '作成する',
    all: '全権限',
    partial: '一部権限',
  },
  pageHeader: {
    title: '管理者情報登録',
    back: '戻る',
  },
}
