import {
  DeleteAttendanceLogRequest,
  DeleteAttendanceLogResponse,
  GetAttendanceLogsRequest,
  GetAttendanceLogsResponse,
} from '@/types/apis/attendanceLogs'
import { ExportAttendanceLogsRequest } from '@/types/apis/attendanceLogs/ExportAttendanceLogsRequest'
import { ExportAttendanceLogsResponse } from '@/types/apis/attendanceLogs/ExportAttendanceLogsResponse'

import axios from './axios'

const API_ENDPOINT = '/attendance-logs'

export const getAttendanceLogs = (request: GetAttendanceLogsRequest): Promise<GetAttendanceLogsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const deleteAttendanceLog = (request: DeleteAttendanceLogRequest): Promise<DeleteAttendanceLogResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const exportAttendanceLogs = (request: ExportAttendanceLogsRequest): Promise<ExportAttendanceLogsResponse> => {
  return axios.post(`${API_ENDPOINT}/export`, request)
}
