import { create } from './Create/Create.i18n.en'
import { list } from './List/List.i18n.en'
import { update } from './Update/Update.i18n.en'
import { userTypeModal } from './UserTypeModal/UserTypeModal.i18n.en'
export const admins = {
  list,
  create,
  update,
  userTypeModal,
}
