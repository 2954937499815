export const importFromCsv = {
  importModalTitle: 'Confirm import employees',
  importSuccessfully: 'Import employees successfully!',
  importFailed: 'Import employees failed!',
  select: 'Select',
  name: 'Name',
  code: 'Employee code',
  uid: 'UID',
  firstName: 'First name',
  lastName: 'Last name',
  firstNameKana: 'First name (kana)',
  lastNameKana: 'Last name (kana)',
  gender: 'Gender',
  companyJoinDate: 'Company join date',
  dob: 'DOB',
  language: 'Language',
}
