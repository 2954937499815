import { notification } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type OpenMessage = {
  message?: string
  description?: string
  type?: 'success' | 'error'
}

const useNotification = () => {
  const { t } = useTranslation()
  return useCallback(
    ({ message, description, type = 'success' }: OpenMessage) => {
      notification[type]({
        message: message || t('common.notification'),
        description,
      })
    },
    [t]
  )
}

export default useNotification
