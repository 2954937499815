export const imports = {
  form: {
    clickOrDrag: 'ここにファイルをドロップしてアップロード',
    supportMessage:
      'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
  },
  pageHeader: {
    title: 'CSV Import',
  },
}
