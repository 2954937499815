export const list = {
  table: {
    id: 'Id',
    text: 'Text',
    startAt: 'Start At',
    endAt: 'End At',
    displayTarget: 'Display target',
    special: 'Special',
    public: 'All employees',
    single: 'Single',
    multiple: 'Multiple',
    published: 'Published',
    question: 'Question',
    action: 'Action',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this employee?',
    happyBirthday: 'Happy birthday',
    joiningCompany: 'Joining company',
    titleModal: 'List of employees tagged with message',
  },
  pageHeader: {
    title: 'Messages',
    new: 'New',
    csvImport: 'CSV Import',
  },
}
