import { useCallback, useState } from 'react'

import { deleteTerminal, getTerminals } from '@/apis/terminals'
import { DeleteTerminalRequest, GetTerminalsResponse } from '@/types/apis/terminals'

export const useTerminals = () => {
  const [getTerminalsLoading, setGetTerminalsLoading] = useState(false)
  const [deleteTerminalLoading, setDeleteTerminalLoading] = useState(false)
  const [getTerminalsResponse, setGetTerminalsResponse] = useState<GetTerminalsResponse>()
  const get = useCallback(async () => {
    try {
      setGetTerminalsLoading(true)
      const res = await getTerminals({})
      setGetTerminalsResponse(res)
    } finally {
      setGetTerminalsLoading(false)
    }
  }, [])

  const del = useCallback(
    async ({ id }: DeleteTerminalRequest) => {
      try {
        setDeleteTerminalLoading(true)
        await deleteTerminal({ id })

        if (getTerminalsResponse) {
          const newTerminals = getTerminalsResponse?.filter((item) => item.id !== id)
          setGetTerminalsResponse([...newTerminals])
        }
      } finally {
        setDeleteTerminalLoading(false)
      }
    },
    [getTerminalsResponse]
  )
  return {
    getTerminalsLoading,
    getTerminals: get,
    getTerminalsResponse,
    deleteTerminal: del,
    deleteTerminalLoading,
  }
}
