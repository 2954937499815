import { Button, Popconfirm, Space, Table, TablePaginationConfig, Tag } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'
import { Announcement, Employee } from '@/types'
type Props = {
  dataSource: Announcement[] | undefined
  page: number
  pageSize: number
  totalItems: number
  getAnnouncementsLoading: boolean
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: Announcement['id']) => void
  onGetData: (value: Employee[]) => void
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const AnnouncementsTable = ({
  dataSource,
  getAnnouncementsLoading,
  page,
  pageSize,
  totalItems,
  onChange,
  onDelete,
  onGetData,
  setIsModalVisible,
}: Props) => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  const navigate = useNavigate()
  const [visibleConfirmDelete, setVisibleComfirmDelete] = useState<Record<string, boolean>>({})

  const columns = [
    {
      title: t('components.pages.announcements.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.announcements.list.table.text'),
      dataIndex: 'text',
      key: 'text',
      render: (text: string, record: Announcement) => {
        if (!record.specialType) return record.text
        switch (record.specialType) {
          case 'happyBirthday':
            return (
              <span>
                <Tag color="blue">{t('components.pages.announcements.list.table.happyBirthday')}</Tag> {record.text}
              </span>
            )
          case 'joiningCompany':
            return (
              <span>
                <Tag color="blue">{t('components.pages.announcements.list.table.joiningCompany')}</Tag> {record.text}
              </span>
            )
          default:
            return ''
        }
      },
    },
    {
      title: t('components.pages.announcements.list.table.startAt'),
      dataIndex: 'startAt',
      key: 'startAt',
      render: (text: string, record: Announcement) => (record.startAt ? moment(record.startAt).format('L') : ''),
    },
    {
      title: t('components.pages.announcements.list.table.endAt'),
      dataIndex: 'endAt',
      key: 'endAt',
      render: (text: boolean, record: Announcement) => (record.endAt ? moment(record.endAt).format('L') : ''),
    },
    {
      title: t('components.pages.announcements.list.table.displayTarget'),
      dataIndex: 'displayTarget',
      key: 'displayTarget',
      render: (text: boolean, record: Announcement) => {
        if (record.isPublic) {
          return t('components.pages.announcements.list.table.public')
        } else if (!!record.specialType) {
          return t('components.pages.announcements.list.table.special')
        } else {
          if (record.employees.length === 1) {
            return (
              <>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    onGetData(record.employees)
                    setIsModalVisible(true)
                  }}
                >
                  {t('components.pages.announcements.list.table.single')}
                </Button>
              </>
            )
          }
          return (
            <>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  onGetData(record.employees)
                  setIsModalVisible(true)
                }}
              >
                {t('components.pages.announcements.list.table.multiple')}
              </Button>
            </>
          )
        }
      },
    },
    {
      title: t('components.pages.announcements.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      // eslint-disable-next-line
      render: (text: string, record: Announcement) => {
        return (
          <Space size="middle">
            {hasPermission('annoucement/update') ? (
              <Button type="link" size="small" onClick={() => goToEditAnnouncementPage(record.id)}>
                {t('components.pages.announcements.list.table.update')}
              </Button>
            ) : null}

            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.announcements.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleComfirmDelete({})}
            >
              <Button type="link" size="small" danger onClick={() => setVisibleComfirmDelete({ [record.id]: true })}>
                {t('components.pages.announcements.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const goToEditAnnouncementPage = (id: Announcement['id']) => {
    navigate(`${routes.announcements.path}/${id}`)
  }
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={getAnnouncementsLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
      rowKey="id"
    />
  )
}

AnnouncementsTable.displayName = 'AnnouncementsTable'
export default AnnouncementsTable
