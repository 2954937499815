export const exportHistory = {
  table: {
    date: 'Date',
    fileName: 'File name',
    size: 'File size',
    action: 'Action',
  },
  pageHeader: {
    title: 'Matching data export history',
  },
}
