export const update = {
  form: {
    id: 'Id',
    code: 'Code',
    name: 'Name',
    size: 'Size',
    requireCode: 'Please input uniform code!',
    requireName: 'Please input uniform name!',
    requireSize: 'Please input uniform size!',
    update: 'Update',
  },
  pageHeader: {
    title: 'Update uniform',
    back: 'Back',
  },
}
