export const update = {
  form: {
    name: '言語',
    code: 'コード',
    isDefault: 'デフォルト？',
    submit: '作成する',
    requireName: '言語は必須です',
    requireCode: 'コードは必須です',
    update: '保存する',
  },
  pageHeader: {
    title: '言語更新',
    back: '戻る',
  },
}
