import React, { useEffect } from 'react'

import { CreateAnnouncementForm, CreateAnnouncementPageHeader } from '@/components/pages/Announcements/Create'
import { useCreateAnnouncements } from '@/pages/Announcements/Create/Create.hooks'

import { useGetEmployees, useGetLanguages } from '../Update/Update.hooks'

const CreateAnnouncement = () => {
  const { createAnnouncement, loading } = useCreateAnnouncements()
  const { getLanguages, getLanguagesResponse } = useGetLanguages()
  const { getEmployees, getEmployeesResponse } = useGetEmployees()

  useEffect(() => {
    getLanguages().then()
    getEmployees().then()
  }, [getEmployees, getLanguages])

  return (
    <>
      <CreateAnnouncementPageHeader />
      <CreateAnnouncementForm
        loading={loading}
        getLanguagesResponse={getLanguagesResponse}
        getEmployeesResponse={getEmployeesResponse}
        onSubmit={createAnnouncement}
      />
    </>
  )
}

CreateAnnouncement.displayName = 'CreateAnnouncement'
export default CreateAnnouncement
