import axios, { AxiosError, AxiosResponse } from 'axios'

import { apiHost } from '@/configs/api'
import routes from '@/constants/routes'

const axiosInstance = axios.create({
  baseURL: apiHost,
})

const handleResponseSuccess = (response: AxiosResponse) => {
  return Promise.resolve(response.data)
}

const handleResponseFailed = (error: AxiosError) => {
  if (error.response?.status === 401) {
    window.location.href = routes.login.path
    localStorage.clear()
    return
  }
  return Promise.reject(error.response?.data || { message: error.message })
}

axiosInstance.interceptors.response.use(handleResponseSuccess, handleResponseFailed)

export default axiosInstance
