import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, PageHeader } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'

import KanaSearch from './KanaSearch'
type Props = {
  onKanaSearchChange: (selectedChars: string[]) => void
}

const EmployeesPageHeader: FC<Props> = ({ onKanaSearchChange }) => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  const navigate = useNavigate()

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate(routes.employeesImport.path)}>
        {t('components.pages.employees.list.pageHeader.csvImport')}
      </Menu.Item>
    </Menu>
  )

  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.employees.list.pageHeader.title')}
      extra={[
        <KanaSearch key="kanaSearch" onChange={onKanaSearchChange} />,
        hasPermission('employee/create') ? (
          <Dropdown.Button
            key="action"
            type="primary"
            icon={<DownOutlined />}
            overlay={menu}
            onClick={() => navigate(routes.createEmployee.path)}
          >
            {t('components.pages.employees.list.pageHeader.new')}
          </Dropdown.Button>
        ) : null,
      ]}
    />
  )
}

EmployeesPageHeader.displayName = 'EmployeesPageHeader'
export default EmployeesPageHeader
