import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AdminsPageHeader, AdminsTable } from '@/components/pages/Admin/List'
import routes from '@/constants/routes'
import { Admin } from '@/types'

import { useAdmins } from './Admins.hooks'

const Admins = () => {
  const navigate = useNavigate()
  const { getAdmins, deleteAdmin, getAdminsLoading, deleteAdminLoading, getAdminsResponse, page, pageSize, setPage } =
    useAdmins()
  const dataSource = getAdminsResponse?.items ?? []
  const totalItems = getAdminsResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.admins.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  const handleDeleteLanguage = async (id: Admin['id']) => {
    await deleteAdmin({ id })
  }

  useEffect(() => {
    getAdmins().then()
  }, [getAdmins])

  return (
    <>
      <AdminsPageHeader />
      <AdminsTable
        {...{ dataSource, getAdminsLoading, deleteAdminLoading, page, pageSize, totalItems }}
        onChange={handleChangePage}
        onDelete={handleDeleteLanguage}
      />
    </>
  )
}

Admins.displayName = 'Admins'
export default Admins
