import { Button, Form, Input, Spin, Switch } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { GetLanguageResponse, UpdateLanguageRequest } from '@/types/apis/languages'

export type UpdateLanguageFormValue = Pick<UpdateLanguageRequest, 'name' | 'code' | 'isDefault'>

type Props = {
  updateLoading: boolean
  getLoading: boolean
  getLanguageResponse: GetLanguageResponse | undefined
  onSubmit: (formValues: UpdateLanguageFormValue) => void
}

const UpdateLanguageForm = ({ getLoading, updateLoading, getLanguageResponse, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (getLanguageResponse) {
      form.setFieldsValue({
        name: getLanguageResponse.name,
        code: getLanguageResponse.code,
        isDefault: getLanguageResponse.isDefault,
      })
    }
  }, [form, getLanguageResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.languages.update.form.name')}
          name="name"
          rules={[{ required: true, message: t('components.pages.languages.update.form.requireName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.languages.update.form.code')}
          name="code"
          rules={[{ required: true, message: t('components.pages.languages.update.form.requireCode') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.languages.update.form.isDefault')}
          valuePropName="checked"
          name="isDefault"
        >
          <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            {t('components.pages.languages.update.form.update')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateLanguageForm.displayName = 'UpdateLanguageForm'
export default UpdateLanguageForm
