export const imports = {
  form: {
    clickOrDrag: 'Click or drag file to this area to upload',
    supportMessage:
      'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
  },
  pageHeader: {
    title: 'CSV Import',
  },
}
