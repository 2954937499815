import 'moment/locale/ja'

import React from 'react'

import AuthProvider from '@/contexts/Auth.context'
import LanguageContextProvider from '@/contexts/Language'
import Routes from '@/routes'

function App() {
  return (
    <AuthProvider>
      <LanguageContextProvider>
        <Routes />
      </LanguageContextProvider>
    </AuthProvider>
  )
}

App.displayName = 'App'
export default App
