export const exportHistory = {
  table: {
    date: '日付',
    fileName: 'ファイル名',
    size: 'サイズ',
    action: 'アクション',
  },
  pageHeader: {
    title: '帳票出力履歴',
  },
}
