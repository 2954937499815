export const create = {
  form: {
    code: 'Code',
    submit: 'Submit',
    requireCode: 'Please input Id card code!',
  },
  pageHeader: {
    title: 'New Id card',
  },
}
