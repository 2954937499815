export const list = {
  table: {
    id: 'ID',
    name: '言語',
    code: 'コード',
    isDefault: 'デフォルト言語',
    action: 'アクション',
    update: '編集',
    delete: '削除',
    confirmDelete: 'この言語を削除しますか。',
  },
  pageHeader: {
    title: '言語一覧',
    new: '新規',
  },
}
