import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { IdCardsPageHeader, IdCardsTable } from '@/components/pages/IdCards/List'
import routes from '@/constants/routes'
import { IdCard } from '@/types'

import { useIdCards } from './IdCards.hooks'

const IdCards = () => {
  const navigate = useNavigate()
  const { getIdCards, getIdCardLoading, getIdCardResponse, page, pageSize, setPage, deleteIdCard } = useIdCards()

  const dataSource = getIdCardResponse?.items ?? []
  const totalItems = getIdCardResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.idCards.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  const handleDeleteIdCard = async (id: IdCard['id']) => {
    await deleteIdCard({ id })
  }
  useEffect(() => {
    getIdCards().then()
  }, [getIdCards])

  return (
    <>
      <IdCardsPageHeader />
      <IdCardsTable
        {...{ dataSource, getIdCardLoading, page, pageSize, totalItems }}
        onChange={handleChangePage}
        onDelete={handleDeleteIdCard}
      />
    </>
  )
}

IdCards.displayName = 'IdCards'
export default IdCards
