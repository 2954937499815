import {
  CreateLanguageRequest,
  CreateLanguageResponse,
  DeleteLanguageRequest,
  DeleteLanguageResponse,
  GetLanguageRequest,
  GetLanguageResponse,
  GetLanguagesRequest,
  GetLanguagesResponse,
  UpdateLanguageRequest,
  UpdateLanguageResponse,
} from '@/types/apis/languages'

import axios from './axios'

const API_ENDPOINT = '/languages'

export const getLanguages = (request: GetLanguagesRequest): Promise<GetLanguagesResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const getLanguage = (request: GetLanguageRequest): Promise<GetLanguageResponse> => {
  return axios.get(`${API_ENDPOINT}/${request.id}`)
}

export const createLanguage = (request: CreateLanguageRequest): Promise<CreateLanguageResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const updateLanguage = (request: UpdateLanguageRequest): Promise<UpdateLanguageResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}`, request)
}

export const deleteLanguage = (request: DeleteLanguageRequest): Promise<DeleteLanguageResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}
