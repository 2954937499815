import { create } from './Create/Create.i18n.en'
import { imports } from './Import/Import.i18n.en'
import { list } from './List/List.i18n.en'
import { update } from './Update/Update.i18n.en'

export const employees = {
  list,
  update,
  imports,
  create,
}
