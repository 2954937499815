export const create = {
  form: {
    name: 'Name',
    code: 'Code',
    isDefault: 'Is Default',
    submit: 'Submit',
    requireName: 'Please input language name!',
    requireCode: 'Please input language code!',
  },
  pageHeader: {
    title: 'New language',
    back: 'Back',
  },
}
