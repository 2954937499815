export const update = {
  form: {
    code: '社員CD',
    firstName: '名',
    lastName: '姓',
    firstNameKana: '名（カナ）',
    lastNameKana: '姓（カナ）',
    kana: 'カナ',
    gender: '性別',
    genderMale: '男性',
    genderFemale: '女性',
    uid: 'ICカードNo.',
    languageCode: '言語',
    companyJoinDate: '入社年月日',
    dob: '生年月日',
    update: '保存する',
    requireFirstName: '名は必須です',
    requireLastName: '姓は必須です',
    requireFirstNameKana: '名（カナ）は必須です',
    requireLastNameKana: '姓（カナ）は必須です',
    requireCode: '社員CDは必須です',
    requireLanguageCode: '言語は必須です',
    requireUid: 'UIDは必須です',
    requireKana: 'カナは必須です',
    requireGender: '性別が必要です',
    requireCompanyJoinDate: '入社年月日は必須です',
    requireDob: '生年月日は必須です',
  },
  pageHeader: {
    title: '社員情報',
    back: '戻る',
  },
}
