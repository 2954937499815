export const list = {
  form: {
    id: 'ID',
    code: 'Code',
    action: 'Action',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this Id card?',
  },
  pageHeader: {
    title: 'ID Cards',
    csvImport: 'CSV Import',
    new: 'New',
  },
}
