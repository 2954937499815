import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { importIdCardFromCsv } from '@/apis/idCards'
import routes from '@/constants/routes'
import useNotification from '@/hooks/useNotification'
import { ImportIdCardFromCsvRequest, ImportIdCardFromCsvResponse } from '@/types/apis/idCards'

export const useImportIdCard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<ImportIdCardFromCsvResponse>()

  const imp = useCallback(
    async (request: ImportIdCardFromCsvRequest) => {
      try {
        setLoading(true)
        const res = await importIdCardFromCsv(request)
        setResponse(res)
        openNotification({ description: t('pages.idCards.import.importSuccessfully') })
        navigate(routes.idCards.path)
      } catch (error) {
        openNotification({ description: t('pages.idCards.import.importFailed'), type: 'error' })
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )

  return {
    loading,
    importIdCard: imp,
    response,
  }
}
