import React, { useEffect } from 'react'

import { TerminalsPageHeader, TerminalsTable } from '@/components/pages/Terminals/List'
import { Terminal } from '@/types'

import { useTerminals } from './Terminals.hooks'

const Terminals = () => {
  const { getTerminals, getTerminalsLoading, getTerminalsResponse, deleteTerminal } = useTerminals()
  const dataSource = getTerminalsResponse ?? []

  const handleDeleteTerminal = async (id: Terminal['id']) => {
    await deleteTerminal({ id })
  }

  useEffect(() => {
    getTerminals().then()
  }, [getTerminals])

  return (
    <>
      <TerminalsPageHeader />
      <TerminalsTable {...{ dataSource, getTerminalsLoading }} onDelete={handleDeleteTerminal} />
    </>
  )
}

Terminals.displayName = 'Terminals'
export default Terminals
