import { useCallback, useState } from 'react'

import { getAttendanceLogExports } from '@/apis/attendanceLogExports'
import { API_LIMIT } from '@/constants/api'
import useQueryPage from '@/hooks/useQueryPage'
import { GetAttendanceLogExportsResponse } from '@/types/apis/attendanceLogExports/GetAttendanceLogExportsResponse'

export const useAttendanceLogExports = () => {
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<GetAttendanceLogExportsResponse>()

  const get = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getAttendanceLogExports({ page, limit: API_LIMIT })
      setResponse(res)
    } finally {
      setLoading(false)
    }
  }, [page])

  return {
    loading,
    getExports: get,
    response,
    page,
    pageSize: API_LIMIT,
    setPage,
  }
}
