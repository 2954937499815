import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LoginPageHeader = () => {
  const { t } = useTranslation()

  return <PageHeader ghost={false} title={t('components.pages.login.list.pageHeader.title')} />
}

LoginPageHeader.displayName = 'LoginPageHeader'
export default LoginPageHeader
