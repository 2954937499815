export const userTypeModal = {
  title: '権限について',
  description: `「全権限」
全てのページ閲覧と帳票出力、編集が可能です。
管理者の登録と権限付与が可能です。

「一部権限」
「管理者登録」ボタン以外のページ閲覧と帳票出力、編集が可能です。
管理者の登録と権限付与は出来ません。`,
}
