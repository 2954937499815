import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAdmin, updateAdmin } from '@/apis/admins'
import useNotification from '@/hooks/useNotification'
import { GetAdminRequest, GetAdminResponse, UpdateAdminRequest, UpdateAdminResponse } from '@/types/apis/admins'

export const useUpdateAdmins = () => {
  const { t } = useTranslation()
  const openNotification = useNotification()
  const [loadingUpdateAdmin, setLoadingUpdateAdmin] = useState(false)
  const [response, setResponse] = useState<UpdateAdminResponse>()

  const update = useCallback(
    async (request: UpdateAdminRequest) => {
      try {
        setLoadingUpdateAdmin(true)
        const res = await updateAdmin(request)
        setResponse(res)
        openNotification({ description: t('pages.employees.update.updateSuccessfully') })
      } finally {
        setLoadingUpdateAdmin(false)
      }
    },
    [openNotification, t]
  )

  return {
    loadingUpdateAdmin,
    updateAdmin: update,
    response,
  }
}

export const useGetAdmin = () => {
  const [loadingGetAdmin, setLoadingGetAdmin] = useState(false)
  const [responseGetAdmin, setResponseGetAdmin] = useState<GetAdminResponse>()

  const get = useCallback(async (request: GetAdminRequest) => {
    try {
      setLoadingGetAdmin(true)
      const res = await getAdmin(request)
      setResponseGetAdmin(res)
    } finally {
      setLoadingGetAdmin(false)
    }
  }, [])

  return {
    loadingGetAdmin,
    getAdmin: get,
    responseGetAdmin,
  }
}
