import {
  CreateIdCardRequest,
  CreateIdCardResponse,
  DeleteIdCardRequest,
  DeleteIdCardResponse,
  GetIdCardsRequest,
  GetIdCardsResponse,
  ImportIdCardFromCsvRequest,
  ImportIdCardFromCsvResponse,
} from '@/types/apis/idCards'

import axios from './axios'
const API_ENDPOINT = '/id-cards'

export const getIdCards = (request: GetIdCardsRequest): Promise<GetIdCardsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const createIdCard = (request: CreateIdCardRequest): Promise<CreateIdCardResponse> => {
  return axios.post(API_ENDPOINT, request)
}

export const deleteIdCard = (request: DeleteIdCardRequest): Promise<DeleteIdCardResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const importIdCardFromCsv = (request: ImportIdCardFromCsvRequest): Promise<ImportIdCardFromCsvResponse> => {
  return axios.post(`${API_ENDPOINT}/import-csv`, request)
}
