import { Button, Form, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { UpdateUniformRequest, UpdateUniformResponse } from '@/types/apis/uniforms'

export type UpdateUniformFormValue = Pick<UpdateUniformRequest, 'code' | 'name' | 'size'>

type Props = {
  updateLoading: boolean
  getLoading: boolean
  getUniformResponse: UpdateUniformResponse | undefined
  onSubmit: (formValues: UpdateUniformFormValue) => void
}

const UpdateUniformForm = ({ updateLoading, getLoading, getUniformResponse, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (getUniformResponse) {
      form.setFieldsValue({
        code: getUniformResponse.code,
        name: getUniformResponse.name,
        size: getUniformResponse.size,
      })
    }
  }, [form, getUniformResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.uniforms.update.form.code')}
          name="code"
          rules={[{ required: true, message: t('components.pages.uniforms.update.form.requireCode') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.uniforms.update.form.name')}
          name="name"
          rules={[{ required: true, message: t('components.pages.uniforms.update.form.requireName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.uniforms.update.form.size')}
          name="size"
          rules={[{ required: true, message: t('components.pages.uniforms.update.form.requireSize') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            {t('components.pages.uniforms.update.form.update')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateUniformForm.displayName = 'UpdateUniformForm'
export default UpdateUniformForm
