export const list = {
  table: {
    id: 'ID',
    name: 'Name',
    code: 'Code',
    isDefault: 'Is Default',
    action: 'Action',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this language?',
  },
  pageHeader: {
    title: 'Languages',
    new: 'New',
  },
}
