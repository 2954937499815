import { isJson } from './json'
type Value<T> = T extends object ? T : string

export const get = <T>(name: string): Value<T> | null => {
  const value = localStorage.getItem(name)
  if (!value) {
    return null
  }

  if (isJson(value)) {
    return JSON.parse(value)
  }

  return value as Value<T>
}

export const set = <T>(name: string, value: T) => {
  if (value === undefined) {
    return
  }

  localStorage.setItem(name, JSON.stringify(value))
}
