export const list = {
  table: {
    id: 'No.',
    firstName: '名',
    lastName: '姓',
    firstNameKana: '名（カナ）',
    lastNameKana: '姓（カナ）',
    userId: 'ユーザーID',
    userType: '権限',
    update: '編集',
    delete: '削除',
    confirmDelete: 'この管理者を削除しますか',
    action: 'アクション',
    all: '全権限',
    partial: '一部権限',
  },
  pageHeader: {
    title: '管理者情報一覧',
    new: '新規',
  },
}
