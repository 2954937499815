import { create } from './Create/Create.i18n.ja'
import { imports } from './Import/Import.i18n.ja'
import { list } from './List/List.i18n.ja'
import { update } from './Update/Update.i18n.ja'

export const uniforms = {
  create,
  list,
  update,
  imports,
}
