import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateAdminRequest } from '@/types/apis/admins'

import { UserTypeModal } from '../UserTypeModal/UserTypeModal'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateAdminRequest) => void
}

const CreateAdminForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showUserTypeModal = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.admins.create.form.lastName')}
        name="lastName"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requireLastName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.admins.create.form.firstName')}
        name="firstName"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requireFirstName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.admins.create.form.lastNameKana')}
        name="lastNameKana"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requireLastNameKana') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.admins.create.form.firstNameKana')}
        name="firstNameKana"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requireFirstNameKana') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.admins.create.form.userId')}
        name="userId"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requireUserId') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.admins.create.form.password')}
        name="password"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requirePassword') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <span>
            {t('components.pages.admins.create.form.userType')}{' '}
            <a href="#" onClick={showUserTypeModal}>
              <QuestionCircleOutlined />
            </a>
          </span>
        }
        name="userType"
        rules={[{ required: true, message: t('components.pages.admins.create.form.requireUserType') }]}
      >
        <Select allowClear>
          <Select.Option value="A">{t('components.pages.admins.create.form.all')}</Select.Option>
          <Select.Option value="B">{t('components.pages.admins.create.form.partial')}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.admins.create.form.summit')}
        </Button>
      </Form.Item>
      <UserTypeModal handleOk={handleOk} isVisible={isModalVisible} />
    </Form>
  )
}

CreateAdminForm.displayName = 'CreateAdminForm'
export default CreateAdminForm
