export const importFromCsv = {
  select: 'Select',
  icChipNo: 'IC Chip No.',
  productName: 'Product name',
  size: 'Size',
  productCode: 'Product code',
  barcode: 'Barcode No.',
  importSuccessfully: 'Import uniforms successfully!',
  importFailed: 'Import uniforms failed!',
}
