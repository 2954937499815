import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateTerminalForm, UpdateTerminalPageHeader } from '@/components/pages/Terminals/Update'
import { UpdateTerminalFormValue } from '@/components/pages/Terminals/Update/Form'
import { useGetTerminal, useUpdateTerminals } from '@/pages/Terminals/Update/Update.hooks'

const UpdateTerminal = () => {
  const params = useParams<{ id: string }>()
  const { updateTerminal, loading: updateLoading } = useUpdateTerminals()
  const { getTerminal, loading: getLoading, response: getTerminalResponse } = useGetTerminal()

  const terminalId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateTerminalFormValue) => {
    await updateTerminal({ ...formValues, id: terminalId })
  }

  useEffect(() => {
    getTerminal({ id: terminalId }).then()
  }, [terminalId, getTerminal, params.id])

  return (
    <>
      <UpdateTerminalPageHeader />
      <UpdateTerminalForm {...{ updateLoading, getLoading, getTerminalResponse }} onSubmit={handleSubmitForm} />
    </>
  )
}

UpdateTerminal.displayName = 'UpdateTerminal'
export default UpdateTerminal
