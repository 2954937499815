import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateIdCardRequest } from '@/types/apis/idCards'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateIdCardRequest) => void
}

const CreateIdCardForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.idCards.create.form.code')}
        name="code"
        rules={[{ required: true, message: t('components.pages.idCards.create.form.requireCode') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.idCards.create.form.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateIdCardForm.displayName = 'CreateIdCardForm'
export default CreateIdCardForm
