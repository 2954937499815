import { useCallback, useState } from 'react'

import { deleteLanguage, getLanguages } from '@/apis/languages'
import { DeleteLanguageRequest, GetLanguagesResponse } from '@/types/apis/languages'

export const useLanguages = () => {
  const [page, setPage] = useState(1)
  const [getLanguagesLoading, setGetLanguagesLoading] = useState(false)
  const [deleteLanguagesLoading, setDeleteLanguagesLoading] = useState(false)
  const [getLanguagesResponse, setLanguagesResponse] = useState<GetLanguagesResponse>()

  const get = useCallback(async () => {
    try {
      setGetLanguagesLoading(true)
      const res = await getLanguages({})
      setLanguagesResponse(res)
    } finally {
      setGetLanguagesLoading(false)
    }
  }, [])

  const del = useCallback(
    async (request: DeleteLanguageRequest) => {
      try {
        setDeleteLanguagesLoading(true)
        await deleteLanguage(request)

        if (getLanguagesResponse) {
          const newLanguages = getLanguagesResponse.filter((item) => item.id !== request.id)
          setLanguagesResponse(newLanguages)
        }
      } finally {
        setDeleteLanguagesLoading(false)
      }
    },
    [getLanguagesResponse]
  )

  return {
    getLanguages: get,
    deleteLanguage: del,
    getLanguagesLoading,
    deleteLanguagesLoading,
    page,
    setPage,
    getLanguagesResponse,
  }
}
