import { useCallback, useState } from 'react'

import { deleteAdmin, getAdmins } from '@/apis/admins'
import { API_LIMIT } from '@/constants/api'
import useQueryPage from '@/hooks/useQueryPage'
import { DeleteAdminRequest, GetAdminsResponse } from '@/types/apis/admins'

export const useAdmins = () => {
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [getAdminsLoading, setGetAdminsLoading] = useState(false)
  const [deleteAdminLoading, setDeleteAdminLoading] = useState(false)
  const [getAdminsResponse, setAdminsResponse] = useState<GetAdminsResponse>()

  const get = useCallback(async () => {
    try {
      setGetAdminsLoading(true)
      const res = await getAdmins({ page, limit: API_LIMIT })
      setAdminsResponse(res)
    } finally {
      setGetAdminsLoading(false)
    }
  }, [page])

  const del = useCallback(
    async (request: DeleteAdminRequest) => {
      try {
        setDeleteAdminLoading(true)
        await deleteAdmin(request)

        if (getAdminsResponse?.items) {
          const newAdmins = getAdminsResponse.items.filter((item) => item.id !== request.id)
          setAdminsResponse({ ...getAdminsResponse, items: newAdmins })
        }
      } finally {
        setDeleteAdminLoading(false)
      }
    },
    [getAdminsResponse]
  )

  return {
    getAdminsLoading,
    getAdmins: get,
    deleteAdmin: del,
    page,
    pageSize: API_LIMIT,
    setPage,
    getAdminsResponse,
    deleteAdminLoading,
  }
}
